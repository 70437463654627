import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { VisibilityTwoTone } from '@mui/icons-material';
import DetailsForm2 from './DetailsForm2';
import { SimpleGrid } from '@chakra-ui/react'
import { TextField } from '@mui/material';
import { format_sold } from './utils';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: 550,
  bgcolor: 'white',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};

export default function ShowForm({data}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen} sx={{ width: '100px' }}>
        <VisibilityTwoTone /> عرض
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} dir="rtl">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            عرض تغدية الصندوق
          </Typography>
          <Box sx={{ width: '100%', height: '150px' }}>
            <SimpleGrid columns={2} spacing={10}>
            <TextField id="outlined-basic" label="التاريخ" variant="outlined" value={data?.Date} />
            <TextField id="outlined-basic" label="الساعة" variant="outlined" />
            <TextField id="outlined-basic" label="المبلغ" variant="outlined" value={format_sold(data?.sold)} />
            <TextField id="outlined-basic" label="المكلف" variant="outlined"  value={data?.taxpayer} />

            </SimpleGrid>
          </Box>
          <DetailsForm2 data={data} />
          <Box sx={{ width: '100%', height: 'auto' }}>
            <Button variant="contained">
              الغاء
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
