import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from 'react';
import supabase from 'src/supabase.config';
// Resuable Components
// import Menuaction from "./menuaction"

// Libs
import currencyFormatter from "currency-formatter"
import { Box, Button, Pagination } from '@mui/material';

//Util Component
import Caisse from "../../components/caisse"
import { InfoTwoTone, Report } from '@mui/icons-material';
import InfoModal from "./infoModal"
import Balance from './balance';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2979ff",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

 
export default function CustomizedTables() {

  const [Changing_Data, setChanging_Data] = useState([])

  const [Depot, setDepot] = useState([{id:1,name:"IFRI"},{id:2,name:"MMD"},{id:3,name:"MMG"}])
  const [start, setstart] = useState(0)
  const [end, setend] = useState(4)

const  handlPagination=(page)=>{
  setstart(page*4)
  setend(page*4+4)
  }



 
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead sx={{ backgroundColor: "blue" }}>
          <TableRow>

           
            <StyledTableCell align="center"> المبلغ </StyledTableCell>
          
            <StyledTableCell align="center"> الصندوق</StyledTableCell>
            <StyledTableCell align="center"> اجراءات</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Depot?.map((row) => (
            <StyledTableRow key={row?.id}>

            
              <StyledTableCell align="center"> <Balance Date={Date}  id={row?.id} /></StyledTableCell>
              <StyledTableCell align="center">  <Caisse id={row?.id}/>  </StyledTableCell>
              <StyledTableCell align="center"> <InfoModal Date={Date} id={row?.id} data={row} /> </StyledTableCell>
              {/* <StyledTableCell align="center"><Menuaction data={row} /></StyledTableCell> */}

            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {/* <Box sx={{mt:5,height:'40px',width:'100%',display:"flex",justifyContent:'center',alingItems:'center'}}>
      <Pagination onClick={(e)=>handlPagination(e.target.textContent)} showFirstButton='true' sx={{direction:'ltr'}}count={10} variant="outlined" shape="rounded" />

      </Box> */}
    </TableContainer>
  );
}
