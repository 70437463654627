import React from 'react';
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { format_sold } from '../operations/utils';

const Report = ({ income, spend }) => {
  const difference = income - spend;

  return (
    <Box py={6}>
      <Paper elevation={3}>
        <Box p={5}>
          <Typography variant="h5" align="center" gutterBottom>
          تقرير الدخل والإنفاق
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Box bgcolor="#81c784" py={3} borderRadius="10px">
                <Typography variant="h6" align="center" style={{ color: '#fff' }}>
                الدخل
                </Typography>
                <Typography variant="h4" align="center" style={{ color: '#fff' }}>
                  {format_sold(income)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box bgcolor="#f44336" py={3} borderRadius="10px">
                <Typography variant="h6" align="center" style={{ color: '#fff' }}>
                والإنفاق
                </Typography>
                <Typography variant="h4" align="center" style={{ color: '#fff' }}>
                  {format_sold(spend)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box py={3} borderRadius="10px">
                <Typography variant="h6" align="center">
                  صافي
                </Typography>
                <Typography variant="h4" align="center" style={{ color: difference >= 0 ? '#81c784' : '#f44336' }}>
                  {format_sold(difference)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default Report;
