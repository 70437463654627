import React, { useState, useEffect } from "react";

// Material Ui
import {
  Grid,
  Box,
  TextField,
  Chip,
  OutlinedInput,
  InputAdornment,
  Typography,
  Button,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { newPayment } from "src/redux/slice";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from 'react-date-picker';
import supabase from "src/supabase.config";
import AddNewOperation from "./AddNewOperation";
import { get_coffre_name } from "../income_caisse/utils";
import { doorsall, get_Forniseur } from "./utils";
import { Add } from "@material-ui/icons";
import ForniseurModal from "./ForniseurModal";
import { todaydate } from "src/utils/helpers";

const InfoForm = () => {
  const dispatch = useDispatch();
  const Payment_Data = useSelector((state) => state.caisse.new_payment);
  const [company, setCompany] = useState(0);
  const [operation, setoperation] = useState(0);
  const [sold, setsold] = useState(0);
  const [OperationType, setOperationType] = useState([]);
  const [Coffre_name, setCoffre_name] = useState("");
  const [Date_payment, setDate_payment] = useState(todaydate)
  const [doorname, setdoorname] = useState("");
  const [sectionname, setsectioname] = useState("");
  const [entrancename, setentrancename] = useState("");
  const [entrance, setentrance] = useState([]);
  const [door, setdoor] = useState();
  const [section, setsection] = useState([]);
  const [concerned, setconcerned] = useState("");

  const [forniseur, setforniseur] = useState("");


  const [Forniseurs_Lis, setForniseurs_Lis] = useState([])

useEffect(() => {
 get_Forniseur().then(res=>{
  setForniseurs_Lis(res)
 })
}, [])


  const handlenewPayment = async (e) => {
    if (e.target.id == "sold") {
      dispatch(newPayment({ key: "sold", value: e.target.value }));
      setsold(e.target.value);
    }
    if (e.target.id == "taxpayer") {
      dispatch(newPayment({ key: "taxpayer", value: e.target.value }));
    }
    if (e.target.name == "caisse") {
      setCompany(e.target.value);
      dispatch(newPayment({ key: "caisse", value: e.target.value }));
    }
    if (e.target.id == "Date_payment") {
      dispatch(newPayment({ key: "date", value: e.target.value }));
    }
    if (e.target.id == "note") {
      setCompany(e.target.value);
      dispatch(newPayment({ key: "note", value: e.target.value }));
    }
    if (e.target.name == "operation") {
      // setoperation(e.target.value)
      dispatch(newPayment({ key: "operation", value: e.target.value }));
    }

    if (e.target.name == "forniseur") {
      setforniseur(e.target.value);
      dispatch(newPayment({ key: "forniseur", value: e.target.value }));
    }
    if (e.target.name == "door") {
      console.log(e);
      // setdoorname(e.target.value)
    
    }

    if (e.target.name == "section") {
      // setsectioname(e.target.value)
      
    }

    if (e.target.name == "entrance") {
      
      
    }
    console.log(Payment_Data);
  };

  let doors = doorsall;
  const get_Operations = async () => {
    let { data: OperationType, error } = await supabase
      .from("OperationType")
      .select("id,Text");
    if (OperationType) setOperationType(OperationType);
  };

  useEffect(() => {
    get_Operations();
  }, []);

  useEffect(() => {
    get_coffre_name(localStorage.getItem("coffre_id")).then((res) => {
      setCoffre_name(res);
    });
  }, []);

  // Subscribe to changes in any record from the collection
  supabase
    .channel("public:OperationType")
    .on(
      "postgres_changes",
      { event: "*", schema: "public", table: "OperationType" },
      (payload) => {
        get_Operations();
      }
    )
    .subscribe();

  const handledoors = (id) => {
    // this all sections of that door
    setsection(doors.filter((item) => item.id == id)[0]["sections"]);
    setdoorname(doors.filter((item) => item.id == id)[0].name);
    dispatch(newPayment({ key: "door", value: doors.filter((item) => item.id == id)[0].name }));
  };
  const handlesections = (id) => {
    setentrance(section.filter((item) => item.id == id)[0]["entrances"]);

    setsectioname(section.filter((item) => item.id == id)[0].name);
    dispatch(newPayment({ key: "section", value: section.filter((item) => item.id == id)[0].name }));
  };

  const handleentrances = (id) => {
    setentrancename(entrance.filter((item) => item.id == id)[0].name);
    dispatch(newPayment({ key: "entrance", value: entrance.filter((item) => item.id == id)[0].name }));
  };

  return (
    <Box sx={{ width: "100%", height: "450px", padding: "4px" }}>
      <Grid container spacing={1}>

      <Grid item xs={6}>
          <Typography>  تاريخ الدفع</Typography>
        
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel
              htmlFor="outlined-adornment-amount"
              sx={{ direction: "ltr" }}
            >
              تاريخ الدفع 
            </InputLabel>
            <br></br>
            <Button variant="contained">
         <input
           type="date"
           id="Date_payment"
           value={Date_payment}
           style={{ color: "white", backgroundColor: "#47AEFF" }}
           onChange={(e) => {setDate_payment(e.target.value);handlenewPayment(e)}}
         />
     </Button>
          </FormControl>
        </Grid>


        <Grid item xs={6}>
          <Typography>مبلغ الدفع</Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel
              htmlFor="outlined-adornment-amount"
              sx={{ direction: "ltr" }}
            >
              مبلغ الدفع{" "}
            </InputLabel>
            <OutlinedInput
              sx={{ direction: "rtl" }}
              id="sold"
              value={sold}
              size="small"
              onChange={(e) => handlenewPayment(e)}
              endAdornment={<InputAdornment position="end"> دج</InputAdornment>}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Typography>المكلف</Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              id="taxpayer"
              label="المكلف"
              size="small"
              variant="outlined"
              onChange={(e) => handlenewPayment(e)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Typography>الصندوق</Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">الصندوق</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              name="caisse"
              value={company}
              label="company"
              size="small"
              onChange={(e) => handlenewPayment(e)}
              defaultValue={localStorage.getItem("coffre_id")}
            >
              <MenuItem value={localStorage.getItem("coffre_id")}>
                {Coffre_name}
              </MenuItem>
              {/* <MenuItem value={2}>MDD</MenuItem>
              <MenuItem value={3}>MMG</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Typography>القسم</Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            {doorname}
            <InputLabel id="demo-simple-select-label"> القسم</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              name="door"
              value={doorname}
              label="operation"
              size="small"
              color="info"
              // onChange={(e) => handlenewPayment(e)}
              onChange={(e) => {
                handledoors(e.target.value);
              
              }}
            >
              {doors.map((door, key) => {
                return <MenuItem key={door.id} value={door.id}>{door.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <Typography>المدخل</Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            {sectionname}
            <InputLabel id="demo-simple-select-label"> المدخل</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              name="section"
              value={sectionname}
              label="operation"
              size="small"
              // onChange={(e) => handlenewPayment(e)}
              onChange={(e) => {
                handlesections(e.target.value);
             
              }}
            >
              {section.map((item, key) => {
                return (
                <MenuItem value={item.id}>{item.name}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <Typography>الباب</Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            {entrancename}
            <InputLabel id="demo-simple-select-label"> الباب</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              name="entrance"
              value={entrancename}
              label="operation"
              size="small"
              // onChange={(e) => handlenewPayment(e)}
              onChange={(e) => {
                handleentrances(e.target.value);
               
              }}
            >
              {entrance.map((item, key) => {
                return <MenuItem value={item.id}>{item.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Typography>الباب</Typography>
        </Grid>
        <Grid item xs={6}>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label"> المورد</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              name="forniseur"
              value={forniseur}
              label="operation"
              size="small"
              // onChange={(e) => handlenewPayment(e)}
              onChange={(e) => handlenewPayment(e)}
            >
              {Forniseurs_Lis.map((item, key) => {
                return <MenuItem value={item?.id}>{item?.label}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          {/* <ForniseurModal /> */}
        </Grid>
        {/* 
        <Grid item xs={6}>
          
         <AddNewOperation/>
        </Grid> */}
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              id="note"
              label=" ملاحضة"
              variant="outlined"
              size="small"
              onChange={(e) => handlenewPayment(e)}
            />
          </FormControl>
      
        </Grid>

        
      </Grid>
    </Box>
  );
};

export default InfoForm;
