import supabase from "src/supabase.config";




export const get_company_sold = async (id_Caisse) => {
    let spend_sold=0
    let { data: incomes_coffre, error } = await supabase
        .from('incomes_coffre')
        .select('sold')
        .eq('caisse', id_Caisse)
    let PrevSold = 0
    if (incomes_coffre) {
        for (let index = 0; index < incomes_coffre.length; index++) {
            PrevSold = incomes_coffre[index].sold + PrevSold
        }
       
        let { data: spends_coffre, error } = await supabase
        .from('spends_coffre')
        .select('sold')
        .eq('caisse', id_Caisse)
        if (spends_coffre) {
            for (let index = 0; index < spends_coffre.length; index++) {
                spend_sold = spend_sold+spends_coffre[index].sold  
            }

        }

    }
    return (PrevSold-spend_sold)
}

export const get_company_sold2 = async (id_Caisse) => {
    let spend_sold=0
    let { data: incomes_coffre, error } = await supabase
        .from('incomes_coffre')
        .select('sold')
        .eq('caisse', id_Caisse)
    let PrevSold = 0
    if (incomes_coffre) {
        for (let index = 0; index < incomes_coffre.length; index++) {
            PrevSold = incomes_coffre[index].sold + PrevSold
        }
       
        let { data: spends_coffre, error } = await supabase
        .from('spends_coffre')
        .select('sold')
        .eq('caisse', id_Caisse)
        if (spends_coffre) {
            for (let index = 0; index < spends_coffre.length; index++) {
                spend_sold = spend_sold+spends_coffre[index].sold  
            }

        }

    }
    return (PrevSold-spend_sold)
}

export const insert_income_history=async(PrevSold,caisse,sold,type,operation_type,details)=>{
    console.log(details)
    const { datahistory, errorhistory } = await supabase
    .from('coffre_history')
    .insert([
      { PrevSold: PrevSold, NewSold: PrevSold + sold, Type: type, sold:sold, caisse: caisse,operation_type },
     
    ])
    .select()
    if(errorhistory)console.log(errorhistory)
    if(datahistory) console.log(datahistory)
}


export const insert_spend_history=async(PrevSold,caisse,sold,type,operation_type,details)=>{
    console.log(details)
    const { datahistory, errorhistory } = await supabase
    .from('coffre_history')
    .insert([
      { PrevSold: PrevSold, NewSold: PrevSold - sold, Type: type, sold:sold, caisse: caisse,operation_type },
     
    ])
    .select()
    if(errorhistory)console.log(errorhistory)
    if(datahistory) console.log(datahistory)
}

export const delay = ( function() {
    var timer = 0;
    return function(callback, ms) {
        clearTimeout (timer);
        timer = setTimeout(callback, ms);
    };
})();