import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

//External libs
import Iconify from 'src/components/Iconify';


// Components
import StepsContainer from "./stepsContainer"
import { clearData } from 'src/redux/slice';
import { useDispatch } from 'react-redux';
import { CreateNewFolder, CreateTwoTone } from '@mui/icons-material';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height:600,
  bgcolor: 'white',
  borderRadius:"5px",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
 
  

  const dispatch=useDispatch()

  const clear_new = () => {
    dispatch(clearData("transfer"))
    dispatch(clearData("income"))
    dispatch(clearData("spend"))
  }


  const handleOpen = () => {
    setOpen(true)

    clear_new()
  };


  const handleClose = () => setOpen(false);

  return (
    <div>
   
      <Button sx={{direction:"ltr",color:'blue',backgroundColor:'white'}} onClick={handleOpen} variant="contained"  to="#" startIcon={<CreateTwoTone/>}>
        جديد
          </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <StepsContainer/>
        </Box>
      </Modal>
    </div>
  );
}
