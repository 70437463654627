import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { MenuIcon } from '@chakra-ui/react';
import { MenuList } from '@material-ui/core';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShowForm from './ShowForm';
import EditForm from './EditForm';
import DeleteForm from './DeleteForm';
export default function FadeMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  console.log(props?.data)
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button 
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
         <MoreVertIcon/>
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem >
            <ShowForm data={props?.data}/>
        </MenuItem>
        <MenuItem >
            <EditForm data={props?.data}/>
        </MenuItem>
        <MenuItem>
            <DeleteForm data={props?.data}/>
        </MenuItem>
      </Menu>
    </div>
  );
}
