import React from 'react'

// Material Ui
import { Box } from '@mui/material'


const thankyouForm = () => {
  return (
    <Box sx={{width:'100%',height:'350px',display:"flex",justifyContent:"center",alignItems:"center"}}>
<img style={{height:'150px',width:'150px'}} src='https://media.istockphoto.com/vectors/approved-and-confirmed-document-file-check-online-on-laptop-computer-vector-id1239295086?k=20&m=1239295086&s=612x612&w=0&h=jaFiAPWgKk6vN-llFSSwUT4tZVrpZQFSbyZH1ZKGGB4='/>
    </Box>
  )
}

export default thankyouForm