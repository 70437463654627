import React from 'react'
import { useState, useEffect } from 'react'


// Material Ui
import { Grid, Box, TextField, Chip } from '@mui/material'

//Redux
import { useDispatch, useSelector } from 'react-redux'
import { newIncome, newTransfer } from "../../redux/slice"



import currencyFormatter from "currency-formatter"




const DetailsForm = () => {
  //Paper 
  // const [B2000, setB2000] = useState(0)
  // const [B1000, setB1000] = useState(0)
  // const [B500, setB500] = useState(0)
  // const [B200, setB200] = useState(0)

  //Coin
  // const [C200, setC200] = useState(0)
  // const [C100, setC100] = useState(0)
  // const [C50, setC50] = useState(0)
  // const [C20, setC20] = useState(0)
  // const [C10, setC10] = useState(0)
  // const [C5, setC5] = useState(0)

  // Total Paper and Coin
 
  const [paperSold, setpaperSold] = useState(0)
  const [coinSOld, setcoinSOld] = useState(0)
  

  const [TotalSold, setTotalSold] = useState(0)

  const dispatch = useDispatch()
  const Income_Data = useSelector((state) => state.caisse.new_income)
  const incerment_enter_key = (e) => {

    if (e.key === "Enter") {
      setenterkeycount(enterkeycount + 1)
    }
  }
  const handlenewIncome = (e) => {
    switch (e.target.id) {

      case 'B2000':
        dispatch(newIncome({ key: 'B2000', value: Number(e.target.value) }))
        break;
      case 'B1000':
        dispatch(newIncome({ key: 'B1000', value:  Number(e.target.value) }))
        break;
      case 'B500':
        dispatch(newIncome({ key: 'B500', value: Number(e.target.value) }))
        break;
      case 'B200':
        dispatch(newIncome({ key: 'B200', value: Number(e.target.value) }))
        break;
      case 'C200':
        dispatch(newIncome({ key: 'C200', value:  Number(e.target.value) }))
        break;
      case 'C100':
        dispatch(newIncome({ key: 'C100', value:  Number(e.target.value) }))
        break;
      case 'C50':
        dispatch(newIncome({ key: 'C50', value:  Number(e.target.value) }))
        break;
      case 'C20':
        dispatch(newIncome({ key: 'C20', value: Number(e.target.value) }))
        break;
      case 'C10':
        dispatch(newIncome({ key: 'C10', value:  Number(e.target.value)}))
        break;
      case 'C5':
        dispatch(newIncome({ key: 'C5', value:  Number(e.target.value) }))
        break;
    }
    console.log(Income_Data)
  } 

 
    useEffect(() => {
      setTotalSold(Income_Data.B2000*2000+Income_Data.B1000*1000+Income_Data.B500*500+Income_Data.B200*200+Income_Data.C200*200+Income_Data.C100*100+Income_Data.C50*50+Income_Data.C20*20+Income_Data.C10*10+Income_Data.C5*5)
      setpaperSold(Income_Data.B2000*2000+Income_Data.B1000*1000+Income_Data.B500*500+Income_Data.B200*200)
      setcoinSOld(Income_Data.C200*200+Income_Data.C100*100+Income_Data.C50*50+Income_Data.C20*20+Income_Data.C10*10+Income_Data.C5*5)
    });

    const [enterkeycount, setenterkeycount] = useState(0)
    const inputRef1 = React.useRef();
    const inputRef2 = React.useRef();
    const inputRef3 = React.useRef();
    const inputRef4 = React.useRef();
   
    const B2000Ref1 = React.useRef();
    const B1000Ref2 = React.useRef();
    const B500Ref3 = React.useRef();
    const B200Ref4 = React.useRef();

    const C200Ref = React.useRef();
    const C100Ref = React.useRef();
    const C50Ref = React.useRef();
    const C20Ref = React.useRef();
  
    const C10Ref = React.useRef();
    const C5Ref = React.useRef();
  
    const inputRef5 = React.useRef();
    useEffect(() => {
      switch (enterkeycount) {
        case 1:
          // code block
          B2000Ref1.current.focus();
           
         
          break;
        case 2:
          // code block
          B1000Ref2.current.focus();
           
          break;
  
        case 3:
          // code block
          B500Ref3.current.focus();
  
       
          break;
        case 4:
          // code block
          B200Ref4.current.focus();
          
          break;
       
  
        case 5:
          // code block
  
  
          C200Ref.current.focus();
          break;
        case 6:
          // code block
          C100Ref.current.focus();
          break;
        case 7:
          // code block
          C50Ref.current.focus();
          break;
        case 8:
          // code block
          C20Ref.current.focus();
          break;
        case 9:
          // code block
          C10Ref.current.focus();
          break;
        case 10:
          // code block
          C5Ref.current.focus();
          break;
        case 11:
          // code block
          setenterkeycount(0)
  
          break;
         
  
        // code block
      }
    }, [enterkeycount]);
  return (
    <Box sx={{ width: '100%', height: '350px', padding: '40px' }} 
    onKeyPress={(e) => incerment_enter_key(e)}
    >
      <Box  sx={{display:"flex",width:'300px',height:'120px'}}>

     
      <Chip sx={{ mb: 4,ml:2 , backgroundColor: "yellowgreen", color: "white" }} label={"المبلغ الورقي  "+currencyFormatter.format(paperSold, {
                symbol: "دج",
                decimal: ",",
                thousand: ".",
                precision: 2, 
                format: "%v %s", // %s is the symbol and %v is the value
              })} />
                <Chip sx={{ mb: 4,ml:2, backgroundColor: "yellowgreen", color: "white",direction:'rtl' }} label={" المبلغ المعدني  "+currencyFormatter.format(coinSOld, {
                symbol: "دج",
                decimal: ",",
                thousand: ".",
                precision: 2,
                format: "%v %s", // %s is the symbol and %v is the value
              })} />
               <Chip sx={{ mb: 4,ml:2  ,backgroundColor: "yellowgreen", color: "white" }} label={"   المبلغ الاجمالي  "+currencyFormatter.format(TotalSold, {
                symbol: "دج",
                decimal: ",",
                thousand: ".",
                precision: 2, 
                format: "%v %s", // %s is the symbol and %v is the value
              })} />
               </Box>
     
      <Grid container spacing={1}>
     
        <Grid item xs={3}>
          <TextField id="B2000" label="  2000 دج" inputRef={B2000Ref1} value={Income_Data.B2000} variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="B1000" label="1000 دج" inputRef={B1000Ref2} value={Income_Data.B1000} variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="B500" label="500 دج" inputRef={B500Ref3} value={Income_Data.B500} variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="B200" label="200 دج" inputRef={B200Ref4} value={Income_Data.B200} variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>

      </Grid>
      <br></br>
     
      <Grid container spacing={1}>
 
        <Grid item xs={3}>
          <TextField id="C200" label="200 دج" value={Income_Data.C200} inputRef={C200Ref} variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="C100" label="100 دج" value={Income_Data.C100} inputRef={C100Ref} variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>

        <Grid item xs={3}>
          <TextField id="C50" label="50 دج" value={Income_Data.C50} inputRef={C50Ref} variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="C20" label="20 دج"  value={Income_Data.C20}  inputRef={C20Ref}  variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>
      </Grid>
      <br></br>
      <Grid container spacing={1}>

        <Grid item xs={3}>
          <TextField id="C10" label="10 دج" inputRef={C10Ref}  value={Income_Data.C10}  variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="C5" label="5 دج" inputRef={C5Ref} value={Income_Data.C5} variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>


      </Grid>
    </Box>
  )
}

export default DetailsForm