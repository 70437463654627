import supabase from "src/supabase.config";

import currencyFormatter from "currency-formatter"



export const get_all_alimentation_caisse=async(startDate,endDate)=>{
    let { data: incomes_coffre, error } = await supabase
    .from('incomes_coffre')
    .select('*')
    .eq('caisse',localStorage.getItem("coffre_id"))
    .neq("type","changement")
    // .gte('created_at', startDate)
    // .lte('created_at', endDate)
    if(incomes_coffre)
    return incomes_coffre

}


export const format_sold=(sold)=>{
    return currencyFormatter.format(sold, {
        symbol: "دج",
        decimal: ",",
        thousand: ".",
        precision: 2,
        format: "%v %s", // %s is the symbol and %v is the value
      })
}




export const delete_income=async(income_id)=>{

    const { data, error } = await supabase
    .from('incomes_coffre')
    .delete()
    .eq('id', income_id)
    if(data)return data
    if(error)console.log(error)
  
}



export const get_coffre_name=async(coffre_id)=>{
    
let { data: caisse, error } = await supabase
.from('caisse')
.select('*')
.eq('id',coffre_id)
if(caisse) {
    console.log(caisse)
    return caisse[0]?.name
}

}