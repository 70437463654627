
import * as React from 'react';
import {useEffect} from 'react'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Box, Link } from '@mui/material';
import { useSelector } from 'react-redux';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars({ active }) {
  const proceedingAlert=useSelector(state=>state.caisse.proceedingAlert)
  const [open, setOpen] = React.useState(proceedingAlert);
useEffect(() => {
  if(proceedingAlert) setOpen(true);
  
}, [proceedingAlert])

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (

    <Stack spacing={2} sx={{ width: '100%' }}>
      {/* <Button variant="outlined" onClick={handleClick}>
        Open success snackbar
      </Button> */}
      <Snackbar open={open} autoHideDuration={null} onClose={handleClose}>
        <Alert onClose={handleClose} sx={{ width: '400px', height: '150px', backgroundColor: "white" }}>
          محضر تحويل الأموال من الصندوق افري
          <Box sx={{ width: '100%', height: '40px',marginTop:2 }}>
            <Button variant="contained" sx={{marginBottom:2}}>
              الموافقة على تحويل أموال الصندوق افري
            </Button>
            <Button variant="contained">
            
              <a href="http://localhost:3001/dashboard/Proceeding" underline="none">
              رؤية التفاصيل
</a>
            </Button>
          </Box>
        </Alert>

      </Snackbar>


    </Stack>
  );
}
