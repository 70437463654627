import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import AlimentationTable from "./AlimentationTable"
//Utils
import supabase from 'src/supabase.config';
// Resuable Components
import Menuaction from "./menuaction"

// Libs
import currencyFormatter from "currency-formatter"
import { useDispatch, useSelector } from 'react-redux';

//Redux
import { updateDataTime } from "../../redux/slice"


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2979ff",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

 

export default function CustomizedTables() {
   
  const [Changing_Data, setChanging_Data] = useState([])
  const [income_Data, setincome_Data] = useState([])
    const [start, setstart] = useState(0)
    const [end, setend] = useState(4)

  const  handlPagination=(page)=>{
    setstart(page*4)
    setend(page*4+4)
    }
  var count = 0


  useEffect(() => {
    const get_income_data = async () => {
      let { data: income, error } = await supabase
        .from('incomes_coffre')
        .select('*')
        .eq('caisse',localStorage.getItem("coffre_id"))
        .range(start, end)
        .order('id', { ascending: false })
       // .eq('type','alimentation_caisse')
        .eq('type','Mini-Closing')
      if (error) console.log(error)
      if (income) {
       
        console.log(income)
        setincome_Data(income)
        
      }
    }
    get_income_data()



 
    

  supabase
  .channel('public:incomes_coffre')
  .on('postgres_changes', { event: '*', schema: 'public', table: 'incomes_coffre' }, payload => {
    console.log('Change received!', payload)
     //Leaten if data inserted update the table
     get_income_data()
  })
  .subscribe()

  }, [start]);
  return (
    // <TableContainer component={Paper}>
    //   <Table sx={{ minWidth: 700 }} aria-label="customized table">
    //     <TableHead sx={{ backgroundColor: "blue" }}>
    //       <TableRow>

    //         <StyledTableCell align="center">التاريخ </StyledTableCell>
    //         {/* <StyledTableCell align="center"> الساعة</StyledTableCell> */}
    //         <StyledTableCell align="center"> المبلغ </StyledTableCell>
    //         <StyledTableCell align="center"> الصندوق</StyledTableCell>
    //         <StyledTableCell align="center"> الملاحظة</StyledTableCell>
    //         <StyledTableCell align="center"> النوع</StyledTableCell>
    //         <StyledTableCell align="center"> اجراءات</StyledTableCell>
    //       </TableRow>
    //     </TableHead>
    //     <TableBody>
    //     {  income_Data.length!=0 ?
    //       income_Data.map((row) => (
    //         <StyledTableRow key={row?.id}>

    //           <StyledTableCell align="center">{row?.created_at?.split(':')[0]?.split('T')[0]}</StyledTableCell>
    //           {/* <StyledTableCell align="center">{row?.Time?.split('.')[0]}</StyledTableCell> */}
    //           <StyledTableCell align="center">{
    //             currencyFormatter.format(row?.sold, {
    //               symbol: "دج",
    //               decimal: ",",
    //               thousand: ".",
    //               precision: 2,
    //               format: "%v %s", // %s is the symbol and %v is the value
    //             })
    //           }</StyledTableCell>
             
    //           <StyledTableCell align="center">{(row?.caisse==1?"IFRI":row?.caisse==2?"MDD":"MMG")}</StyledTableCell>
    //           <StyledTableCell align="center">{row?.note}</StyledTableCell>
    //           <StyledTableCell align="center">{row?.type}</StyledTableCell>

    //           <StyledTableCell align="center"><Menuaction data={row} /></StyledTableCell>

    //         </StyledTableRow>
    //       )):" "}
    //     </TableBody>
    //   </Table>
    //   <Box sx={{mt:5,height:'40px',width:'100%',display:"flex",justifyContent:'center',alingItems:'center'}}>
    //   <Pagination onClick={(e)=>handlPagination(e.target.textContent)} showFirstButton='true' sx={{direction:'ltr'}}count={10} variant="outlined" shape="rounded" />

    //   </Box>
     
    // </TableContainer>

    <>
    <AlimentationTable   data={income_Data} />
    </>
  );
}
