import React, { useEffect, useState } from 'react'


// Material Ui
import { Grid, Box, TextField, Chip, Typography } from '@mui/material'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { newPayment, newRest } from 'src/redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import supabase from 'src/supabase.config';

const InfoForm = () => {
  const dispatch = useDispatch();
  const Rest_Data = useSelector((state) => state.caisse.new_rest);
  const [company, setCompany] = useState(0);
  const [operation, setoperation] = useState(0);

const [payments, setpayments] = useState([])




  const handlenewRest = async (e) => {
    if (e.target.id == 'sold') {
      dispatch(newRest({ key: 'sold', value: e.target.value }))
    }
    if (e.target.id == 'taxpayer') {
      dispatch(newRest({ key: 'taxpayer', value: e.target.value }))
    }
    if (e.target.name == 'caisse') {

      setCompany(e.target.value)
      dispatch(newRest({ key: 'caisse', value: e.target.value }))
    }
    if (e.target.name == 'operation') {

      setoperation(e.target.value)
      dispatch(newRest({ key: 'operation', value: e.target.value }))
    }
    console.log('rests')
    console.log(Rest_Data)

  }

  useEffect(() => {
 const get_payments=async()=>{
  
let { data: payments_coffre, error } = await supabase
.from('payments_coffre')
.select('*')
.eq('caisse',company)
if(payments_coffre) setpayments(payments_coffre)
 
 }
 get_payments()
  },[company]);
  return (
    <Box sx={{ width: '100%', height: '350px', padding: '40px' }}>
      <Chip sx={{ mb: 5 }} label="   الصندوق   MDD" />
      <Grid container spacing={1}>

        <Grid item xs={6}>

          <Chip label='  التاريخ 12-01-2022      ' />
        </Grid>
        <Grid item xs={6}>
          <Chip label=' الساعة  12:01:01      ' />

        </Grid>


      </Grid>
      <br></br>
      <Grid container spacing={1}>
      <Grid item xs={6}>
     <Typography  >
     المبلغ
      </Typography> 
     </Grid>
        <Grid item xs={6}>
        <FormControl fullWidth>
        <TextField id="sold" size="small" label="المبلغ " variant="outlined" onChange={(e) => handlenewRest(e)} />

        </FormControl>
         
        </Grid>
        <Grid item xs={6}>
     <Typography  >
     المكلف
      </Typography> 
     </Grid>
        <Grid item xs={6}>
        <FormControl fullWidth>
        <TextField id="taxpayer" size="small" label="المكلف" variant="outlined" onChange={(e) => handlenewRest(e)} />

        </FormControl>
        
        </Grid>
        <Grid item xs={6}>
     <Typography  >
     الصندوق
      </Typography> 
     </Grid>
        <Grid item xs={6}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>

              <InputLabel id="demo-simple-select-label">الصندوق</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                name="caisse"
                value={company}
                label="company"
                size="small"
                onChange={(e) => handlenewRest(e)}
              >
                <MenuItem value={1}>IFRI</MenuItem>
                <MenuItem value={2}>MDD</MenuItem>
                <MenuItem value={3}>MMG</MenuItem>
              </Select>
            </FormControl>

          </Box>
        </Grid>
        <Grid item xs={6}>
     <Typography  >
     نوع العملية
      </Typography> 
     </Grid>
        <Grid item xs={6}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">نوع العملية</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                name="operation"
                value={operation}
                size="small"
                label="operation"
                onChange={(e) => handlenewRest(e)}
              >
             
              {
                payments.map((payment)=>{
                  return(
                     
                      <MenuItem key={payment.id} value={payment.id}>  {payment.sold}-{payment.operation}{payment?.created_at}</MenuItem>
                   
                  )
                })
              }
              </Select>
            </FormControl>
          </Box>
        </Grid>


      </Grid>
    </Box>
  )
}

export default InfoForm