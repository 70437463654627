import * as React from 'react';
import { useState, useEffect } from 'react';

//MUI
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import { Box } from '@mui/system';



// Supabase
import supabase from 'src/supabase.config';
// Resuable Components
import Menuaction from "./menuaction"

// Libs
import currencyFormatter from "currency-formatter"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2979ff",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



export default function CustomizedTables() {

  const [spends_Data, setspends_Data] = useState([])
  const [start, setstart] = useState(0)
  const [end, setend] = useState(4)

const  handlPagination=(page)=>{
  setstart(page*4)
  setend(page*4+4)
  }


  useEffect(() => {
    const get_Spends_data = async () => {
      let { data: spends, error } = await supabase
        .from('spends_coffre')
        .select("*")
        .range(start, end)
        .order('id', { ascending: false })
      if (spends) {
        console.log(spends)
        setspends_Data(spends)
      }
    }
    get_Spends_data()


    const subscritpion = supabase
      .from("spends_coffre") // here, you can put '*' if you want to listen to changes on every table or you can put name of a specifict table
      .on("*", (payload) => {
        console.log(payload);
        // Leaten if data inserted update the table
        get_Spends_data()
      })
      .subscribe();
    return () => {
      supabase.removeSubscription(subscritpion);
    };

  }, [start]);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead sx={{ backgroundColor: "blue" }}>
          <TableRow>

            <StyledTableCell align="center">التاريخ </StyledTableCell>
            <StyledTableCell align="center"> الساعة</StyledTableCell>
            <StyledTableCell align="center"> المبلغ المحول</StyledTableCell>
            <StyledTableCell align="center"> الصندوق</StyledTableCell>
            <StyledTableCell align="center"> النوع</StyledTableCell>
            <StyledTableCell align="center"> اجراءات</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {spends_Data.map((row) => (
            <StyledTableRow key={row?.id}>

              <StyledTableCell align="center">{row?.created_at?.split(':')[0]?.split('T')[0]}</StyledTableCell>
              <StyledTableCell align="center">{row?.Time?.split('.')[0]}</StyledTableCell>
              <StyledTableCell align="center">{
                currencyFormatter.format(row?.sold, {
                  symbol: "دج",
                  decimal: ",",
                  thousand: ".",
                  precision: 2,
                  format: "%v %s", // %s is the symbol and %v is the value
                })
              }</StyledTableCell>
              <StyledTableCell align="center">{(row?.caisse==1?"IFRI":row?.caisse==2?"MDD":"MMG")}</StyledTableCell>
              <StyledTableCell align="center">{row?.type}</StyledTableCell>

              <StyledTableCell align="center"><Menuaction data={row} /></StyledTableCell>

            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <Box sx={{mt:5,height:'40px',width:'100%',display:"flex",justifyContent:'center',alingItems:'center'}}>
      <Pagination onClick={(e)=>handlPagination(e.target.textContent)} showFirstButton='true' sx={{direction:'ltr'}}count={10} variant="outlined" shape="rounded" />

      </Box>
    </TableContainer>
  );
}
