import * as React from 'react';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, ButtonBase } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

//React Lib
import {useEffect} from "react"

// component
import Iconify from 'src/components/Iconify';


//Utils

import supabase from 'src/supabase.config';




const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  borderRadius:"2px",
  boxShadow: 24,
  p: 4,
};

export default function TransitionsModal({data}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDelete=async()=>{
    const { data:changement, error } = await supabase
  .from('spends_coffre')
  .delete()
  .eq('id', data.id)
  if(error)console.log(error)
  }

   
  
 

  useEffect(() => {

  },[]);
  return (
    <div>
      
      <MenuItem sx={{ color: 'text.secondary' }} onClick={handleOpen}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="مسح" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade direction="up"  in={open}  mountOnEnter unmountOnExit>
          <Box sx={style}>
         {data.id}
         {data.Time}
         <Button onClick={handleDelete}>
          Delete me
         </Button>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
