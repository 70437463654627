import supabase from "src/supabase.config"
 
import currencyFormatter from "currency-formatter"

export const get_operation=async()=>{

    let { data: OperationType, error } = await supabase
    .from('OperationType')
    .select('*')
    if(OperationType){
        return OperationType
    }
  
}

export const add_operation=async()=>{


}

export const calculate_Spending=async(data)=>{
    let Total_Spending=0
for (let index = 0; index < data.length; index++) {
    Total_Spending=data[index].sold+Total_Spending
	 
}
return Total_Spending
}


export const get_archives=async(startDate,endDate)=>{
    let { data: OperationType, error } = await supabase
    .from('coffre_history')
    .select('*')
   
    .gte('Date',startDate)
    .lte('Date',endDate)
    if(OperationType)
    return OperationType

}


export const format_sold=(sold)=>{
    return currencyFormatter.format(sold, {
        symbol: "دج",
        decimal: ",",
        thousand: ".",
        precision: 2,
        format: "%v %s", // %s is the symbol and %v is the value
      })
}