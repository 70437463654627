import supabase from "src/supabase.config";










export const get_income_coffre=async(coffre_id,date)=>

{
  
    
let { data: incomes_coffre, error } = await supabase
.from('incomes_coffre')
.select('*')
.eq('caisse',coffre_id)

.eq("Date", date)
if(incomes_coffre){
  console.log(" i am incomemme")
  console.log(incomes_coffre)
    return incomes_coffre
}
}


export const get_spend_coffre=async(coffre_id,date)=>

{
  
    
let { data: spends_coffre, error } = await supabase
.from('spends_coffre')
.select('*')
.eq('caisse',coffre_id)

 
.eq("Date", date)
if(spends_coffre){
  console.log("spend coffre")
  console.log(spends_coffre)
    return spends_coffre
}
}


export const Total_Incomes=(data)=>{
    const totalSold = data.reduce((acc, curr) => acc + curr.sold, 0);
    console.log(totalSold)
const totalDetails =data.reduce((acc, curr) => {
  acc.B2000 += curr.B2000;
  acc.B1000 += curr.B1000;
  acc.B500 += curr.B500;
  acc.B200 += curr.B200;
  acc.C200 += curr.C200;
  acc.C100 += curr.C100;
  acc.C50 += curr.C50;
  acc.C20 += curr.C20;
  acc.C10 += curr.C10;
  acc.C5 += curr.C5;
  return acc;
}, {
  B2000: 0,
  B1000: 0,
  B500: 0,
  B200: 0,
  C200: 0,
  C100: 0,
  C50: 0,
  C20: 0,
  C10: 0,
  C5: 0,
});

return {totalSold,totalDetails}
  }