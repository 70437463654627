import { Box, Button } from "@mui/material";
import React, { useState,useEffect } from "react";
import { todaydate } from "src/utils/helpers";
import { Total_Incomes, get_income_coffre, get_spend_coffre } from "./utils";
import { format_sold } from "../operations/utils";
import Report from "./Report";

const DayReport = () => {
  const [startDate, setStartDate] = useState(todaydate);
  const [endDate, setEndDate] = useState(todaydate);
  const [Income_data, setIncome_data] = useState([])
  const [Spend_data, setSpend_data] = useState([])

    useEffect(() => {
     get_income_coffre(localStorage.getItem("coffre_id"),startDate).then(res=>{
      if(res)
        setIncome_data(res)
     })

     get_spend_coffre(localStorage.getItem("coffre_id"),startDate).then(res=>{
      if(res)
        setSpend_data(res)
     })
    }, [startDate])
    


  return (
//     <div>
     
//    <Box sx={{width:'100%',height:'40px',display:"flex",justifyContent:"flex-end"}}>
// تقرير يومي 
// <Button variant="contained">
//         <input
//           type="date"
//           value={startDate}
//           style={{ color: "white", backgroundColor: "#47AEFF" }}
//           onChange={(e) => setStartDate(e.target.value)}
//         />
//       </Button>
//    </Box>
//    <Box sx={{width:'100%',height:'40px',display:"flex",justifyContent:"flex-end"}}>
//     <div>
//     {format_sold(JSON.stringify(Total_Incomes(Income_data)?.totalSold))}  
//     </div>
  
   
   
//    قيمة المداخيل الاجمالية
//    </Box>
//       <br></br>
//       <Box sx={{width:'100%',height:'40px',display:"flex",justifyContent:"flex-end"}}>
//     <div>
//     {format_sold(JSON.stringify(Total_Incomes(Spend_data)?.totalSold))}  
//     </div>
  
   
   
//    قيمة المصاريف الاجمالية
//    </Box>
 
//       <br></br>

//       <Box sx={{width:'100%',height:'40px',display:"flex",justifyContent:"flex-end"}}>
//     <div>
//     {format_sold(JSON.stringify(Total_Incomes(Income_data)?.totalSold)-JSON.stringify(Total_Incomes(Spend_data)?.totalSold)) }

//     </div>
  
   
   
//   الصافي
//    </Box>
     

      
//     </div>
<>
 <Button variant="contained">
         <input
           type="date"
           value={startDate}
           style={{ color: "white", backgroundColor: "#47AEFF" }}
           onChange={(e) => setStartDate(e.target.value)}
         />
     </Button>
<Report income={JSON.stringify(Total_Incomes(Income_data)?.totalSold)} spend={JSON.stringify(Total_Incomes(Spend_data)?.totalSold)} />
</>

  );
};

export default DayReport;
