// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';

// ----------------------------------------------------------------------

//Redux Toolkit
import { store } from './redux/store'
import { Provider } from 'react-redux'

// //Supabase with SWR
// import { createClient } from 'supabase-js';
// import { SwrSupabaseContext } from 'supabase-swr';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// pick a date util library
// import MomentUtils from '@date-io/moment';
import DateFnsUtils from '@date-io/date-fns';
import CustomizedSnackbars from './pages/payment_caisse/snackBar';
// import LuxonUtils from '@date-io/luxon';

// const client = createClient('https://cghyrhumhokmgnrpkelf.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImNnaHlyaHVtaG9rbWducnBrZWxmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjU0ODI1NzIsImV4cCI6MTk4MTA1ODU3Mn0.rvx_xqShzl4lMGSm156zsUZeBrU9ozavoMgkxMhBPag');

export default function App() {
 
  return (
    // <SwrSupabaseContext.Provider value={client}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>  
    <Provider store={store}>
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <CustomizedSnackbars/>
      <Router />
    </ThemeProvider>
    </Provider>
    </MuiPickersUtilsProvider>
    // </SwrSupabaseContext.Provider>
  );
}
