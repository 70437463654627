//React
import { useState } from "react"

// @mui


//utils
import supabase from 'src/supabase.config';



// ----------------------------------------------------------------------

 
const useFilter = async (Time, Date, caisse) => {
    const [data, setdata] = useState([])
     
        let { data: changement, error } = await supabase
            .from('changement')
            .select(`
            *,
            caisse (
              *
            )
          `).eq('caisse',caisse)
            // .eq('caisse',1)
        if (changement)  return changement

     
    // if (Time != '' && Date != '') {
    //     let { data: changement, error } = await supabase
    //         .from('changement')
    //         .select('*')
    //         .filter('Date', 'eq', Date)
    //     if (changement) setdata(changement)

    //     return data

    // }
    // else if (Time == '' && Date != '') {
    //     let { data: changement, error } = await supabase
    //         .from('changement')
    //         .select('*')
    //     return changement;
    // }
    // else if (Time != '' && Date == '') {
    //     let { data: changement, error } = await supabase
    //         .from('changement')
    //         .select('*')
    //     return changement;
    // }
    // else if (Time == '' && Date == '') {
    //     let { data: changement, error } = await supabase
    //         .from('changement')
    //         .select('*')
    //     return changement;
    // }
    // else return null;
}

export default useFilter


