import { Chip } from '@mui/material'
import React ,{useState,useEffect} from 'react'
import supabase from 'src/supabase.config'

function getTotalSold(records) {
    let totalSold = 0;
    for (let i = 0; i < records.length; i++) {
      totalSold += records[i].sold;
    }
    return totalSold;
  }


  const calculateTotal = (records) => {
    let totalB2000 = 0;
    let totalB1000 = 0;
    let totalB500 = 0;
    let totalB200 = 0;
    let totalC200 = 0;
    let totalC100 = 0;
    let totalC50 = 0;
    let totalC20 = 0;
    let totalC10 = 0;
    let totalC5 = 0;
  
    for (const record of records) {
      // Add the value of each bill and coin to its corresponding total variable
      totalB2000 += record.B2000 || 0;
      totalB1000 += record.B1000 || 0;
      totalB500 += record.B500 || 0;
      totalB200 += record.B200 || 0;
      totalC200 += record.C200 || 0;
      totalC100 += record.C100 || 0;
      totalC50 += record.C50 || 0;
      totalC20 += record.C20 || 0;
      totalC10 += record.C10 || 0;
      totalC5 += record.C5 || 0;
    }
  
    // Calculate the total value of all bills and coins
    const total = (totalB2000 * 2000) + (totalB1000 * 1000) + (totalB500 * 500) + (totalB200 * 200) + (totalC200 * 200) + (totalC100 * 100) + (totalC50 * 50) + (totalC20 * 20) + (totalC10 * 10) + (totalC5 * 5);
    
    return { totalB2000, totalB1000, totalB500, totalB200, totalC200, totalC100, totalC50, totalC20, totalC10, totalC5, total };
  };  

const get_Coffre=async(coffre_id)=>{
    let { data: caisse, error } = await supabase
    .from('caisse')
    .select('name')
    .eq("id",coffre_id)
    if(caisse)return caisse[0].name
}



const CoffreUtil = ({ Util_type }) => {
    const [coffre, setcoffre] = useState("")
    var currentdate = new Date();

    var datetime = currentdate.getDate() + "-" + (currentdate.getMonth() + 1)
        + "-" + currentdate.getFullYear()

    var time = currentdate.getHours() + ":"
        + currentdate.getMinutes() + ":" + currentdate.getSeconds();

useEffect(() => {
  get_Coffre(localStorage.getItem("coffre_id")).then(res=>{
    setcoffre(res)
  })

  
}, [])



    return (
        <>

            {
                (Util_type == "Coffre") ? <Chip sx={{ mb: 5 }} label={"الخزينة "+coffre} /> : (Util_type == "Date") ? <Chip sx={{ mb: 5 }} label={"التاريخ  "+datetime} /> : (Util_type == "Time") ? <Chip sx={{ mb: 5 }} label={" الساعة  " +time} /> : ""
            }
        </>
    )
}



export const get_Total_Income=async(caisse_id)=>{

    let { data: incomes_coffre, error } = await supabase
    .from('incomes_coffre')
    .select('*')
    .eq('caisse',10)
    if(incomes_coffre){
        console.log(incomes_coffre)
        
        return getTotalSold(incomes_coffre)
    }
  
}


export const get_Total_Details_Income=async(coffre_id)=>{
    
    
    let { data: incomes_coffre, error } = await supabase
    .from('incomes_coffre')
    .select('*')
    .eq('caisse',coffre_id)
    if(incomes_coffre){
        console.log(incomes_coffre)
        
        return calculateTotal(incomes_coffre)
    }
    
   
}


export const get_Total_Spends=async(caisse_id)=>{

    let { data: incomes_coffre, error } = await supabase
    .from('incomes_coffre')
    .select('*')
    .eq('caisse',10)
    if(incomes_coffre){
        console.log(incomes_coffre)
        
        return getTotalSold(incomes_coffre)
    }
  
}

export default CoffreUtil