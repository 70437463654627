import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Chip,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
import supabase from "../../supabase.config"
// mock



// Resuable Components
import Menuaction from "./menuaction"
import MenuPopover from 'src/components/MenuPopover';
import IncomeTable from "./incomeTable"
import NewModal from "./newModal"
import SearchFilter from "./searchFIlter"
import { useSelector } from 'react-redux';
import ChangsTable from './ChangsTable';


export default function Change_coffre() {
const SearcheActive=useSelector((state)=>state.caisse.SearcheActive)


 

 
 
  return (
    <Page title="Changement Coffre">
      <Container sx={{direction:"rtl"}}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          تبديل العملات
          </Typography>
       

          <NewModal/>
          {/* <NewInModal/>
          <NewOutModal/> */}
        </Stack>
{/* <SearchFilter/> */}
{/* <Box sx={{width:'100',height:'50px'}}>
<Chip sx={{backgroundColor:"#6495ED",color:"white"}} label='عدد العمليات 32'/>
</Box> */}
        <Card>
         
          {/* <Scrollbar>
           <IncomeTable/>
          </Scrollbar> */}

        <ChangsTable/>
        </Card>
      </Container>
    </Page>
  );
}
