import * as React from 'react';
import {useState,useEffect} from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { SimpleGrid } from '@chakra-ui/react'
import { Edit_income, get_income } from 'src/utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { updateIncome } from 'src/redux/slice';

export default function DetailsEdit({data}) {
    const [value, setValue] = React.useState('1');
    const [incomeData, setincomeData] = useState([])
   const [update, setupdate] = useState(true)
   const updateIncomevar=useSelector(state=>state.caisse.updateIncomevar)
const dispatch=useDispatch()

    const [B2000, setB2000] = useState(data?.B2000)
    const [B1000, setB1000] = useState(data?.B1000)
    const [B500, setB500] = useState(data?.B500)
    const [B200, setB200] = useState(data?.B200)


    const [C200, setC200] = useState(data?.C200)
    const [C100, setC100] = useState(data?.C100)
    const [C50, setC50] = useState(data?.C50)
    const [C20, setC20] = useState(data?.C20)
    const [C10, setC10] = useState(data?.C10)
    const [C5, setC5] = useState(data?.C5)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // useEffect(() => {
    //  get_income(data?.id).then(res=>{
    //     setincomeData(res)
    //     console.log(res)
    //  })
 
    // }, [])
    useEffect(() => {
        
   
        if(updateIncomevar){
            console.log("i am updated")
           Edit_income(data?.id,B2000,B1000,B500,B200,C200,C100,C50,C20,C10,C5).then(res=>{
dispatch(updateIncome(false))
           })
        }
       }, [updateIncomevar])
    return (
        <Box sx={{ width: '100%', typography: 'body1', direction: "rtl" }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="ورق " value="1" />
                        <Tab label="معدن " value="2" />

                    </TabList>
                </Box>
                <TabPanel value="1">
                    <SimpleGrid columns={2} spacing={10}>
 

                        <FormControl sx={{ mb:5, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>ورق</InputLabel>
                            <OutlinedInput
                            value={B2000}
                            onChange={(e)=>setB2000(e.target.value)}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "2000 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>
                        <FormControl sx={{ mb:5, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>ورق</InputLabel>
                            <OutlinedInput
                              value={B1000}
                              onChange={(e)=>setB1000(e.target.value)}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "1000 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>
                        <FormControl sx={{ mb:5, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>ورق</InputLabel>
                            <OutlinedInput
                              value={B500}
                              onChange={(e)=>setB500(e.target.value)}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "500 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb:5, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>ورق</InputLabel>
                            <OutlinedInput
                              value={B200}
                              onChange={(e)=>setB200(e.target.value)}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start" >


                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "200 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>
                    </SimpleGrid>
                </TabPanel>
                <TabPanel value="2">
                    <SimpleGrid columns={3} spacing={2}>


                        <FormControl sx={{ mb:5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                              value={C200}
                              onChange={(e)=>setC200(e.target.value)}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "200 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb:5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                              value={C100}
                              onChange={(e)=>setC100(e.target.value)}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "100 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb:5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                              value={C50}
                              onChange={(e)=>setC50(e.target.value)}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "50 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb:5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                              value={C20}
                              onChange={(e)=>setC20(e.target.value)}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "20 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb:5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                              value={C10}
                              onChange={(e)=>setC10(e.target.value)}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "10 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb:5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                              value={C5}
                              onChange={(e)=>setC5(e.target.value)}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "5 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                    </SimpleGrid>

                </TabPanel>

            </TabContext>
        </Box>
    );
}
