import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from 'react';
import supabase from 'src/supabase.config';
// Resuable Components
import Menuaction from "./menuaction"

// Libs
import currencyFormatter from "currency-formatter"
import { Box, Chip, Pagination } from '@mui/material';


//Util Components
import Caisse from 'src/components/caisse';
import { useSelector } from 'react-redux';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2979ff",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function CustomizedTables() {
  const SearcheActive=useSelector((state)=>state.caisse.SearcheActive)
  const FilterData = useSelector((state) => state.caisse.FilterData)
  const [Changing_Data, setChanging_Data] = useState([])
  const [start, setstart] = useState(0)
  const [end, setend] = useState(4)

  const handlPagination = (page) => {
    setstart(page * 4)
    setend(page * 4 + 4)
  }



  useEffect(() => {
    const get_Changing_data = async () => {
      let { data: payments, error } = await supabase
        .from('payments_coffre')
        .select("*")
        .range(start, end)
        .order('id', { ascending: false })
      if (error) console.log(error)
      if (payments) {
        
        console.log(payments)
        if (SearcheActive==true) setChanging_Data(FilterData)
        else setChanging_Data(payments)

      }
    }
    get_Changing_data()



    supabase
      .channel('public:payments_coffre')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'payments_coffre' }, payload => {
        console.log('Change received!', payload)
        //Leaten if data inserted update the table
        get_Changing_data()
      })
      .subscribe()
 

  }, [start,SearcheActive]);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead sx={{ backgroundColor: "blue" }}>
          <TableRow>

            <StyledTableCell align="center">التاريخ </StyledTableCell>
            <StyledTableCell align="center"> الساعة</StyledTableCell>
            <StyledTableCell align="center"> المبلغ </StyledTableCell>
            <StyledTableCell align="center"> العملية </StyledTableCell>
            <StyledTableCell align="center"> الصندوق</StyledTableCell>
            <StyledTableCell align="center"> المكلف</StyledTableCell>
            <StyledTableCell align="center"> اجراءات</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Changing_Data?.map((row) => (
            <StyledTableRow key={row?.id}>

              <StyledTableCell align="center">{row?.created_at?.split(':')[0]?.split('T')[0]}</StyledTableCell>
              <StyledTableCell align="center">{row?.created_at?.split('T')[1]?.split('.')[0]}</StyledTableCell>
              <StyledTableCell align="center">
                <Chip label={
                  currencyFormatter.format(row?.sold, {
                    symbol: "دج",
                    decimal: ",",
                    thousand: ".",
                    precision: 2,
                    format: "%v %s", // %s is the symbol and %v is the value
                  })
                } />
              </StyledTableCell>
              <StyledTableCell align="center">{row?.operation}</StyledTableCell>
              <StyledTableCell align="center"> <Caisse id={row?.caisse} /></StyledTableCell>
              <StyledTableCell align="center">{row?.taxpayer}</StyledTableCell>

              <StyledTableCell align="center"><Menuaction data={row} /></StyledTableCell>

            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <Box sx={{ mt: 5, height: '40px', width: '100%', display: "flex", justifyContent: 'center', alingItems: 'center' }}>
        <Pagination onClick={(e) => handlPagination(e.target.textContent)} showFirstButton='true' sx={{ direction: 'ltr' }} count={10} variant="outlined" shape="rounded" />

      </Box>
    </TableContainer>
  );
}
