import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


//Sub Components
import InfoForm from './infoForm';
import DetailsForm from "./detailsForm"
import ThankyouForm from "./thankyouForm"


// Supabase
import supabase from 'src/supabase.config';
import { useSelector } from 'react-redux';
import { get_company_sold, insert_income_history } from 'src/utils/utilsSupabase';



const steps = ['ادخال المعلومات', 'ادخال تفاصيل المبلغ  '];

export default function HorizontalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const Rest_data =useSelector(state=>state.caisse.new_rest)
  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };


  const handleChanging=async()=>{
    handleNext()
    console.log(Rest_data)
    

    get_company_sold(Rest_data.caisse).then(res=>{
      console.log(res)
      
      insert_income_history(res,Rest_data.caisse,Rest_data.sold,"IN","res_operation",Rest_data)
    })
 


    // Get Balance of caisse In
    let { data: balance, errorcaisse } = await supabase
  .from('caisse')
  .select('balance')
  .eq('id', Rest_data.caisse)
if(balance){
  // Add New Balance
  const { data:add_balance, error_add } = await supabase
  .from('caisse')
  .update({ balance:balance[0].balance+Number(Rest_data.sold) })
  .eq('id', Rest_data.caisse)
  if(error_add)console.log(error_add)
}


    const { data:income, error } = await supabase
  .from('incomes_coffre')
  .insert([
    { sold:Rest_data.sold, type: 'rest',caisse:Rest_data.caisse,B2000:Rest_data.B2000,B1000:Rest_data.B1000,B500:Rest_data.B500,B200:Rest_data.B200,C200:Rest_data.C200,C100:Rest_data.C100,C50:Rest_data.C50,C20:Rest_data.C20,C10:Rest_data.C10,C5:Rest_data.C5, },
  ])
  .select()
  if(income){
     const { rest, error } = await supabase
  .from('rests')
  .insert([
    { sold:Rest_data.sold , caisse: Rest_data.caisse,payment:Rest_data.operation,taxpayer:Rest_data.taxpayer,income:income[0].id  },
  ])
  .select()
  if(rest)console.log('rest is done ')
  if(error) console.log(error)
  }
 
  
  }
  return (
    <Box sx={{ width: '100%', direction: "rtl" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption"></Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <ThankyouForm />
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>اعادة</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
          {(activeStep + 1 == 1) ?
            <InfoForm /> :
            (activeStep + 1 == 2)
              ? <DetailsForm />
              : <DetailsForm />
          }
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              رجوع
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}

         

            {
             ( activeStep === steps.length - 1)?
             <Button onClick={handleChanging}>
              حفظ
             </Button>:
              <Button onClick={handleNext}>
              التالي
             </Button>

            }
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
