import * as React from 'react';
import { useState,useEffect } from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { SimpleGrid } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux';
import { Edit_change, Edit_income, Edit_spend } from './utils';
import { updateIncome } from 'src/redux/slice';
export default function DetailsForm2({ data }) {

    console.log(data)
    const [value, setValue] = React.useState('1');
    const updateIncomevar=useSelector(state=>state.caisse.updateIncomevar)
    const dispatch=useDispatch()

    const [B2000, setB2000] = useState(data?.B2000_out)
    const [B1000, setB1000] = useState(data?.B1000_out)
    const [B500, setB500] = useState(data?.B500_out)
    const [B200, setB200] = useState(data?.B200_out)


    const [C200, setC200] = useState(data?.C200_out)
    const [C100, setC100] = useState(data?.C100_out)
    const [C50, setC50] = useState(data?.C50_out)
    const [C20, setC20] = useState(data?.C20_out)
    const [C10, setC10] = useState(data?.C10_out)
    const [C5, setC5] = useState(data?.C5_out)


    const [B2000_in, setB2000_in] = useState(data?.B2000_in)
    const [B1000_in, setB1000_in] = useState(data?.B1000_in)
    const [B500_in, setB500_in] = useState(data?.B500_in)
    const [B200_in, setB200_in] = useState(data?.B200_in)


    const [C200_in, setC200_in] = useState(data?.C200_in)
    const [C100_in, setC100_in] = useState(data?.C100_in)
    const [C50_in, setC50_in] = useState(data?.C50_in)
    const [C20_in, setC20_in] = useState(data?.C20_in)
    const [C10_in, setC10_in] = useState(data?.C10_in)
    const [C5_in, setC5_in] = useState(data?.C5_in)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    useEffect(() => {
    
        if(updateIncomevar) {
   // get in and out from changing
       // update changing
       //update income
       //update spend

            Edit_spend(data?.spend,B2000,B1000,B500,B200,C200,C100,C50,C20,C10,C5).then(res => {
                Edit_income(data?.income, B2000_in, B1000_in, B500_in, B200_in, C200_in, C100_in, C50_in, C20_in, C10_in,C5_in).then(res => {
                    Edit_change(data?.id,B2000,B1000,B500,B200,C200,C100,C50,C20,C10,C5,B2000_in, B1000_in, B500_in, B200_in, C200_in, C100_in, C50_in, C20_in, C10_in,C5_in).then(res=>{
                        dispatch(updateIncome(false))
                        console.log("update happend")
                    })
                })
            })
        }
     
    }, [updateIncomevar])
    
    return (
        <Box sx={{ width: '100%', typography: 'body1', direction: "rtl" }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="ورق خارج" value="1" />
                        <Tab label="معدن خارج  " value="2" />
                        <Tab label="ورق داخل " value="3" />
                        <Tab label="معدن داخل " value="4" />
                        <Tab label="   تفاصيل لتأكد " value="5" />

                    </TabList>
                </Box>
                <TabPanel value="1">
                    <SimpleGrid columns={2} spacing={10}>


                        <FormControl sx={{ mb: 5, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>ورق</InputLabel>
                            <OutlinedInput
                                value={B2000}
                                onChange={(e)=>setB2000(e.target.value)}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "2000 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>
                        <FormControl sx={{ mb: 5, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>ورق</InputLabel>
                            <OutlinedInput
                                value={B1000}
                                onChange={(e)=>setB1000(e.target.value)}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "1000 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>
                        <FormControl sx={{ mb: 5, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>ورق</InputLabel>
                            <OutlinedInput
                                value={B500}
                                onChange={(e)=>setB500(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "500 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb: 5, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>ورق</InputLabel>
                            <OutlinedInput
                                value={B200}
                                onChange={(e)=>setB200(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start" >


                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "200 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>
                    </SimpleGrid>
                </TabPanel>
                <TabPanel value="2">
                    <SimpleGrid columns={3} spacing={2}>


                        <FormControl sx={{ mb: 5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                                value={C200}
                                onChange={(e)=>setC200(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "200 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb: 5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                                value={C100}
                                onChange={(e)=>setC100(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "100 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb: 5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                                value={C50}
                                onChange={(e)=>setC50(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "50 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb: 5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                                value={C20}
                                onChange={(e)=>setC20(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "20 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb: 5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                                value={C10}
                                onChange={(e)=>setC10(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "10 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb: 5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                                value={C5}
                                onChange={(e)=>setC5(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "5 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                    </SimpleGrid>

                </TabPanel>


                <TabPanel value="3">
                    <SimpleGrid columns={2} spacing={10}>


                        <FormControl sx={{ mb: 5, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>ورق</InputLabel>
                            <OutlinedInput
                                value={B2000_in}
                                onChange={(e)=>setB2000_in(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "2000 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>
                        <FormControl sx={{ mb: 5, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>ورق</InputLabel>
                            <OutlinedInput
                                value={B1000_in}
                                onChange={(e)=>setB1000_in(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "1000 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>
                        <FormControl sx={{ mb: 5, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>ورق</InputLabel>
                            <OutlinedInput
                                value={B500_in}
                                onChange={(e)=>setB500_in(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "500 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb: 5, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>ورق</InputLabel>
                            <OutlinedInput
                                value={B200_in}
                                onChange={(e)=>setB200_in(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start" >


                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "200 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>
                    </SimpleGrid>
                </TabPanel>
                <TabPanel value="4">
                    <SimpleGrid columns={3} spacing={2}>


                        <FormControl sx={{ mb: 5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                                value={C200_in}
                                onChange={(e)=>setC200_in(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "200 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb: 5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                                value={C100_in}
                                onChange={(e)=>setC100_in(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "100 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb: 5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                                value={C50_in}
                                onChange={(e)=>setC50_in(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "50 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb: 5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                                value={C20_in}
                                onChange={(e)=>setC20_in(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "20 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb: 5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                                value={C10_in}
                                onChange={(e)=>setC10_in(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "10 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb: 5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                                value={C5_in}
                                onChange={(e)=>setC5_in(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "5 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                    </SimpleGrid>

                </TabPanel>
                <TabPanel value="5">

                </TabPanel>
            </TabContext>
        </Box>
    );
}
