import { Chip } from '@mui/material';
import React, { useState, useEffect } from 'react'
import supabase from 'src/supabase.config';


//Libs
import currencyFormatter from "currency-formatter"
import { useSelector } from 'react-redux';
// //Supabase SWR
// import { useClient, useSelect, useQuery } from 'supabase-swr';

const Balance = ({ id,Date }) => {
    const Report_Date=useSelector(state=>state.caisse.reportDate)
    var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = yyyy + '-' + mm + '-' + dd;

    const [caisseName, setcaisseName] = useState("")
    const [TotalBalance, setTotalBalance] = useState(0)
    const [BalanceIncome, setBalanceIncome] = useState(0)
    const [BalanceSpend, setBalanceSpend] = useState(0)
   var TotalIncome=0
   var TotalSpend=0
    useEffect(() => {
        console.log("Date")
        console.log(Date)
        const get_caisse = async () => {

            let { data: caisse, error } = await supabase
                .from('caisse')
                .select('balance')
                .eq('id', id)
            if (caisse) setcaisseName(caisse[0].balance)
            if (error) console.log(error)
        }

        const get_income=async()=>{
            let { data: income, error } = await supabase
            .from('incomes_coffre')
            .select('sold')
            .eq('caisse', id)
            // .filter('Date','eq',Report_Date)
        if (income) 
        {
            console.log("Hey Khaoula")
            console.log(income)
            for(let i=0;i<income.length;i++){
                TotalIncome=TotalIncome+income[i].sold
            }
            
            setBalanceIncome(TotalIncome)
        }
        if (error) console.log(error)
        }

        const get_spends=async()=>{
            {
                let { data: spends, error } = await supabase
                .from('spends_coffre')
                .select('sold')
                .eq('caisse', id)
                // .filter('Date','eq',Report_Date)
            if (spends) 
            {
                console.log("Hey Khaoula")
                console.log(spends)
                for(let i=0;i<spends.length;i++){
                    TotalSpend=TotalSpend+spends[i].sold
                }
                setBalanceSpend(TotalSpend)
                setTotalBalance(BalanceIncome-BalanceSpend)
                
            }
            if (error) console.log(error)
            }
        }
        
        get_income()
        get_spends()
      

    }, [Date]);
    return (
        <>
            <Chip sx={{color:"blue"}} label={
            currencyFormatter.format(Math.abs(BalanceIncome-BalanceSpend), {
                symbol: "دج",
                decimal: ",",
                thousand: ".",
                precision: 2,
                format: "%v %s", // %s is the symbol and %v is the value
              })
            
            } />
        </>



    )
}

export default Balance