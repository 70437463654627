import React, { useState, useEffect } from "react";
import supabase from "src/supabase.config";
const ForniseurComponent = (props) => {
  const [Fornisuer_name, setFornisuer_name] = useState("");
  useEffect(() => {
    const get_Fornisuer_name = async () => {
      let { data: Forniseur, error } = await supabase
        .from("Forniseur")
        .select("*")
        .eq("id", props?.value);
      if (Forniseur) setFornisuer_name(Forniseur[0]?.label);
    };
    get_Fornisuer_name();
  }, [props]);

  return <div>{Fornisuer_name}</div>;
};

export default ForniseurComponent;
