import { Box, Typography } from '@mui/material'
import React from 'react'
import ProceedingTable from './ProceedingTable'

const Proceeding = () => {
  return (
   <Box sx={{width:"100%",height:'500px',display:"block",paddingLeft:4,paddingRight:4,direction:"rtl"}}>
       <Box sx={{width:"100%",height:'50px',display:"block",backgroundColor:""}}>
<Typography>
    محاضر الصناديق
</Typography>
       </Box>
    <ProceedingTable/>
   </Box>

  )
}

export default Proceeding