import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


//Sub Components
import InfoForm from './infoForm';
import DetailsForm from "./detailsForm"
import ThankyouForm from "./thankyouForm"


// Supabase
import supabase from 'src/supabase.config';
import { useSelector } from 'react-redux';
import { get_company_sold, insert_spend_history } from 'src/utils/utilsSupabase';



const steps = ['ادخال المعلومات', 'ادخال تفاصيل المبلغ  '];

export default function HorizontalLinearStepper() {
  const Income_Data = useSelector((state) => state.caisse.new_income)
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };


  const handleChanging=async()=>{
    handleNext()
   console.log(Income_Data)
  get_company_sold(Income_Data.caisse).then(res=>{
insert_spend_history(res,Income_Data.caisse,Income_Data.sold,"OUT","alimentation_caisse",Income_Data)
  })
const { data, error } = await supabase
.from('spends_coffre')
.insert([
  { sold: Income_Data.sold,note:Income_Data.note,bank:Income_Data.bank, type: 'alimentation_Bank', caisse: Income_Data.caisse, B2000: Income_Data.B2000, B1000: Income_Data.B1000, B500: Income_Data.B500, B200: Income_Data.B200, C200: Income_Data.C200, C100: Income_Data.C100, C50: Income_Data.C50, C20: Income_Data.C20, C10: Income_Data.C10, C5: Income_Data.C5},
       
])
.select()
if(data)console.log("data inserted")
if(error)console.log(error)

  }
  return (
    <Box sx={{ width: '100%', direction: "rtl" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption"></Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <ThankyouForm />
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>اعادة</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
          {(activeStep + 1 == 1) ?
            <InfoForm /> :
            (activeStep + 1 == 2)
              ? <DetailsForm />
              : <DetailsForm />
          }
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              رجوع
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}

         

            {
             ( activeStep === steps.length - 1)?
             <Button onClick={handleChanging}>
              حفظ
             </Button>:
              <Button onClick={handleNext}>
              التالي
             </Button>

            }
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
