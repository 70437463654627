//React
import { useState } from "react"

// @mui


//utils
import supabase from 'src/supabase.config';



// ----------------------------------------------------------------------

 
const useFilter = async (Time, Date, caisse) => {
    const [data, setdata] = useState([])
     
        let { data: Ttransfer_coffre, error } = await supabase
            .from('Ttransfer_coffre')
            .select(`
            *, Caisse_in (
              name
            ),
            Caisse_out (
              name
            )
          `).eq('Caisse_out',caisse)
            // .eq('caisse',1)
        if (Ttransfer_coffre)  return Ttransfer_coffre

     
    // if (Time != '' && Date != '') {
    //     let { data: changement, error } = await supabase
    //         .from('changement')
    //         .select('*')
    //         .filter('Date', 'eq', Date)
    //     if (changement) setdata(changement)

    //     return data

    // }
    // else if (Time == '' && Date != '') {
    //     let { data: changement, error } = await supabase
    //         .from('changement')
    //         .select('*')
    //     return changement;
    // }
    // else if (Time != '' && Date == '') {
    //     let { data: changement, error } = await supabase
    //         .from('changement')
    //         .select('*')
    //     return changement;
    // }
    // else if (Time == '' && Date == '') {
    //     let { data: changement, error } = await supabase
    //         .from('changement')
    //         .select('*')
    //     return changement;
    // }
    // else return null;
}

export default useFilter


