import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';

import Proceeding from './pages/proceeding/Proceeding';

//Sub COmponent
import Change_coffre from './pages/changing_coffre/changing';
import Rest_coffre from './pages/rest_operation_coffre/rest';
import Transfer_coffre from  './pages/transfer_caisse/transfer_coffre_v2'
import Income_caisse from "./pages/income_caisse/income_caisse_v2"
import Aliment_bank from "./pages/aliment_bank/aliment_bank.js"
import Spend_caisse from "./pages/spend_caisse/spend_caisse_v2"
import Open_caisse from "./pages/Open/Open"
import Payment_caisse from "./pages/payment_caisse/payment_caisse"
import Report from  "./pages/reporting/container"
import Operation_In_Out from "./pages/operations/Operation_in_out"
import NewOperation from './pages/operations/NewOperation';
import DayReport from './pages/DayReport/DayReport';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        { path: 'changing', element: <Change_coffre /> },
        { path: 'transfer', element: <Transfer_coffre /> },
        { path: 'rest', element: <Rest_coffre /> },
        { path: 'income_v2', element: <Income_caisse /> },
        { path: 'spend_v2', element: <Spend_caisse /> },
        { path: 'open', element: <Open_caisse /> },
        { path: 'payment', element: <Payment_caisse /> },
        { path: 'report', element: <Report /> },
        { path: 'aliment_bank', element: <Aliment_bank /> },
        { path: 'operation_In_Out', element: <Operation_In_Out /> },
        { path: 'NewOperation', element: <NewOperation /> },
        { path: 'Proceeding', element: <Proceeding /> },
        { path: 'DayReport', element: <DayReport /> },
        

        
        
        
        
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
