import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { SimpleGrid } from '@chakra-ui/react'
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

// React
import {useState,useEffect} from "react"
// ----------------------------------------------------------------------

import supabase from 'src/supabase.config';
import { Box } from '@mui/system';
import BalanceCard from './BalanceCard';
export default function DashboardApp() {
  const theme = useTheme();
const [Depots, setDepots] = useState([])

const [NetIFRI, setNetIFRI] = useState(0)
const [NetMMD, setNetMMD] = useState(0)
const [NetMMG, setNetMMG] = useState(0)
const [Total_income_IFRI, setTotalIFRI_income] = useState(0)
const [Total_income_MMD, setTotalMMD_income] = useState(0)
const [Total_income_MMG, setTotalMMG_income] = useState(0)

const [Total_spends_IFRI, setTotalIFRI_spends] = useState(0)
const [Total_spends_MMD, setTotalMMD_spends] = useState(0)
const [Total_spends_MMG, setTotalMMG_spends] = useState(0)
const [Caisse_id, setCaisse_id] = useState(0)
useEffect(() => {
  setCaisse_id(localStorage?.getItem("coffre_id"))
}, [])



var IFRI_T_Spends=0
var MMG_T_Spends=0
var MDD_T_Spends=0

var IFRI_T_Income=0
var MMG_T_Income=0
var MDD_T_Income=0

const get_Depot=async()=>{
  let { data: caisse, error } = await supabase
  .from('caisse')
  .select('*')
  if (caisse) {
 
    setDepots(caisse)
  }
}

const get_spends=async()=>{
  
  let { data: spends_coffre, error } = await supabase
  .from('spends_coffre')
  .select(`
sold,caisse

`)
if(spends_coffre){
  
  for(let i=0;i<spends_coffre.length;i++){
    if(spends_coffre[i].caisse==1){
    
      IFRI_T_Spends=spends_coffre[i].sold+IFRI_T_Spends
    }
    if(spends_coffre[i].caisse==3){
      MMG_T_Spends=spends_coffre[i].sold+MMG_T_Spends
    }
    if(spends_coffre[i].caisse==2){
      MDD_T_Spends=spends_coffre[i].sold+MDD_T_Spends
    }
  }
 
  setTotalIFRI_spends(IFRI_T_Spends)
  setTotalMMD_spends(MDD_T_Spends)
  setTotalMMG_spends(MMG_T_Spends)

}
if(error){}
}




const get_incomes = async () => {
  let { data: incomes_coffre, error } = await supabase
    .from('incomes_coffre')
    .select(`
  sold,caisse

`)
  if(incomes_coffre){
for(let i=0;i<incomes_coffre.length;i++){
if(incomes_coffre[i].caisse==1){
 
  IFRI_T_Income= incomes_coffre[i].sold+IFRI_T_Income
}
if(incomes_coffre[i].caisse==3){
   
  MMG_T_Income= incomes_coffre[i].sold+MMG_T_Income
}
if(incomes_coffre[i].caisse==2){
  
  MDD_T_Income= incomes_coffre[i].sold+MDD_T_Income
}
}
setTotalIFRI_income(IFRI_T_Income)
setNetIFRI(IFRI_T_Income-IFRI_T_Spends)
setNetMMD(MDD_T_Income-MDD_T_Spends)
setNetMMG(Number(MMG_T_Income)-Number(MMG_T_Spends))
setTotalMMD_income(Number(MDD_T_Income))
setTotalMMG_income(Number(MMG_T_Income))
  }
  if(error){

  }
}
useEffect(() => {
  

  get_incomes()
  get_Depot()
  get_spends()
},[get_Depot,get_incomes,get_spends,IFRI_T_Spends,MMG_T_Spends,MDD_T_Spends,IFRI_T_Income,MMG_T_Income,MDD_T_Income]);



  return (
    <Page title="Dashboard" sx={{direction:"rtl"}}>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5,direction:"rtl" }}>
          احصاء الصناديق
        </Typography>
     
        
      </Container>
    </Page>
  );
}
