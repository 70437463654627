import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Approval, Check } from '@mui/icons-material';
import { CloseIcon } from '@chakra-ui/icons';
import { accept_transfer, activate_counting_caisse, validate_transfer } from './helpers';
import { get_company_sold, insert_income_history } from 'src/utils/utilsSupabase';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 200,
    bgcolor: 'white',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

export default function ApproveTransfer({ data }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
console.log(data)
    const insert_history_proceeding = () => {
        get_company_sold(data.caisse).then(res => {
            console.log(res)

            insert_income_history(res, data.caisse, data.NetSold, "IN", "alimentation_caisse", data)
        })
    }



    return (
        <div>
            <Button onClick={handleOpen}>
                <Approval />
                الموافقة على تحويل مال اصندوق
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} dir="rtl">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        الموافقة على تحويل مال الصندوق
                    </Typography>

                    <Box sx={{ width: "80%", marginTop: 10, height: "30px", display: "flex", justifyContent: "space-between" }}>
                        <Button onClick={() => { insert_history_proceeding(); validate_transfer(data); accept_transfer(data) }} variant="contained">
                            الموافقة <Check />
                        </Button>
                        <Button onClick={handleClose} variant="contained">
                            الغاء
                        </Button>
                    </Box>

                </Box>
            </Modal>
        </div>
    );
}
