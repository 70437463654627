import { Box, Center, Flex } from '@chakra-ui/react'
import { Button, Chip } from '@mui/material';
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import supabase from 'src/supabase.config';
// import DeleteModal from "./deletemodal"

import currencyFormatter from "currency-formatter"
// import { get_all_operation } from './utils';
// import Edit from './Edit';
// import Show from './Show';
// import Delete from './Delete';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { format_sold, get_archives } from './utils';
import CaisseFilter from './CustomFilter/CaisseFilter';
import ActionMenu from "./ActionMenu"
import { todaydate } from 'src/utils/helpers';
import CaisseComponent from '../income_caisse/CaisseComponent';
import Excel from "../../utils/images/excel.png"
import { CalculateSharp, Print } from '@mui/icons-material';

import { calculate_Spending } from './utils';
// import ShowSpend from './ShowSpend';
// import OperationTypeFilter from '../customFilter/OperationTypeFilter';

const dateFilterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split('-');
        var cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
    },
    browserDatePicker: true,
};


const ArchiveTable = ({ data }) => {
    const containerStyle = useMemo(() => ({ width: '100%', height: '80%', direction: "rlt" }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [rowData, setRowData] = useState();
    const [gridApi, setGridApi] = useState(null);
    const inputRef1 = React.useRef();
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [startDate, setStartDate] = useState(todaydate);
    const [endDate, setEndDate] = useState(todaydate);
    // const onGridReady = (params) => {

    //   // setGridApi(params.api);
    //   setGridApi(params)
    //   setGridColumnApi(params.columnApi);

    //   const httpRequest = new XMLHttpRequest();
    //   const updateData = (data) => {
    //     setRowData(data);
    //   };

    //   httpRequest.open(
    //     'GET',
    //     'https://www.ag-grid.com/example-assets/olympic-winners.json'
    //   );
    //   httpRequest.send();
    //   httpRequest.onreadystatechange = () => {
    //     if (httpRequest.readyState === 4 && httpRequest.status === 200) {
    //       updateData(JSON.parse(httpRequest.responseText));
    //     }
    //   };
    // };
    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    }


    const TotalPrice = (props) => {
        return (
            <div>
                <Chip label={format_sold(props?.value)} />

            </div>

        )
    }





    const CodeOperation = (props) => {
        return (

            <>
                {
                    //   (props.value == "مصروف") ? <Chip label={props.value} sx={{ backgroundColor: "tomato", color: "white" }} /> : <Chip label={props.value} sx={{ backgroundColor: "yellowgreen", color: "white" }} />

                }

            </>


        )
    }



    const HoursComponent = (props) => {
        return (
            <div>{props.value?.split('.')[0]}</div>
        )
    }
    const PaperSoldComponent = (props) => {
        return (


            <Chip label={currencyFormatter.format(props.value, {
                symbol: "دج",
                decimal: ",",
                thousand: ".",
                precision: 2,
                format: "%v %s", // %s is the symbol and %v is the value
            })} />
        )
    }

    const CoinSoldComponent = (props) => {
        return (
            <Chip label={currencyFormatter.format(props.value, {
                symbol: "دج",
                decimal: ",",
                thousand: ".",
                precision: 2,
                format: "%v %s", // %s is the symbol and %v is the value
            })} />
        )
    }

 



 


    const [columnDefs, setColumnDefs] = useState([
        {
            field: '', headerName: '', headerCheckboxSelection: true, width: 100, minWidth: 50,
            checkboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            showDisabledCheckboxes: true, filter: false
        },
        { field: 'Date', headerName: 'التاريخ', filter: 'agDateColumnFilter', width: 100, minWidth: 120 },
        { field: 'Time', headerName: ' الساعة', cellRenderer: HoursComponent, width: 100, minWidth: 120 },
        {
            field: 'PrevSold', headerName: '  المبلغ السابق',
             cellRenderer: TotalPrice,
            width: 100, minWidth: 150,
         
        },
        {
            field: 'NewSold', headerName: '  المبلغ الجديد',  cellRenderer: TotalPrice,
            //    cellRenderer: PaperSoldComponent,
            width: 100, minWidth: 160  // pass in additional parameters to the text filter
        },

        {
            field: 'sold', headerName: '    مبلغ العملية',  cellRenderer: TotalPrice,
            //   cellRenderer: CoinSoldComponent,
            width: 100, minWidth: 160  // pass in additional parameters to the text filter
        },

        {
            field: 'caisse',cellRenderer:CaisseComponent,
            //   cellRenderer: TotalPrice,
            headerName: '  الصندوق', width: 100, minWidth: 160, filter: CaisseFilter, filterParams: {
                title: 'Year Filter',
                values: [1,2,3]
            },

 
        },
        {
            field: 'Type', headerName: 'نوع العملية',

            // // cellRenderer: ActionMenu, 
            filter: true, width: 100, minWidth: 220,
        },
        {
            field: 'id', headerName: 'اجرائات',

            // // cellRenderer: ActionMenu, 
            filter: false, width: 100, minWidth: 220,cellRenderer:ActionMenu
        },

    ]);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,

            minWidth: 140,
            filter: true,
            floatingFilter: true,
            filterParams: {
                filterOptions: ['equals']
            }
        };
    }, []);


    const getFilterType = () => {
        if (startDate !== '' && endDate !== '') return 'inRange';
        else if (startDate !== '') return 'greaterThan'
        else if (endDate !== '') return 'lessThan'
    };

    useEffect(() => {

        // get_all_operation(localStorage.getItem('company_supabase_id'),startDate,endDate).then(res => {
        //   setRowData(res)
        // })
        get_archives(startDate,endDate).then(res=>{
            setRowData(res)
        })

    }, [startDate, endDate])


    const [TotalSpending, setTotalSpending] = useState(0)

      const getSelectedRowData = () => {
          setRowData(gridApi.getSelectedRows())
  
          calculate_Spending(gridApi.getSelectedRows()).then(res => {
              setTotalSpending(res)
          })
  
      };

    const handleFocuse = () => {

    }

       // Subscribe to changes in any record from the collection
       supabase
       .channel('public:coffre_history')
       .on('postgres_changes', { event: '*', schema: 'public', table: 'coffre_history' }, payload => {

        get_archives(startDate,endDate).then(res=>{
            setRowData(res)
        })



       })
       .subscribe()



       const sideBar = useMemo(() => {
        return {
          toolPanels: ['filters'],
          
        };
      }, []);
     

    return (
        <Center w="100%" h="500px" bg="" shadow={"lg"} dir="ltr" display={"flex"} flexDirection="column" justifyContent={"center"} alignItems="center" >
            <Flex w="100%" h="60px" p={4} color="green.300" mb={2}>
                <Button variant="contained" onClick={getSelectedRowData} sx={{color:"white",marginLeft:4,marginRight:4}}>
                    Excel تصدير
                    <img src={Excel} style={{color:"white"}} />
                </Button>
                <Button dir="rtl" variant="contained" onClick={getSelectedRowData} sx={{color:"white"}}>
                   طباعة
                 <Print/>
                </Button>
            </Flex>
            <Flex w="500px" justifyContent="space-between" h={"60px"} mb={10} mt={4}>
                <Box>

                    <Button variant="contained" onClick={handleFocuse()}>


                        <input type="date" style={{ color: "white", backgroundColor: "#47AEFF" }} value={endDate} onChange={e => setEndDate(e.target.value)} /> الى
                    </Button>
                </Box>

                <Box>
                    <Button variant="contained">

                        <input type="date" value={startDate} style={{ color: "white", backgroundColor: "#47AEFF" }} onChange={e => setStartDate(e.target.value)} />من
                    </Button>

                </Box>
            </Flex>
            <Flex w="500px" justifyContent="space-between" h={"60px"} mb={10} mt={4}>
            <Chip label={format_sold(TotalSpending)} />

            <Button variant="contained" onClick={getSelectedRowData}>
<CalculateSharp/>  حساب العمليات 
</Button>
            </Flex>

            <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-alpine">
                    <AgGridReact
                        onGridReady={onGridReady}
                        rowData={rowData}
                        sideBar={sideBar}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowSelection="multiple"
                        enableRtl={true}

                    ></AgGridReact>
                </div>
            </div>
        </Center>
    )
}

export default ArchiveTable