import supabase from "src/supabase.config";
// import { todaydate } from "src/utils/formatTime";
// import { get_report_data } from "../conclusion/helpers/helpers";




export const get_id_counting=async()=>{

}




export const create_counting_caisse=async(user,caisse)=>{
// // get all operation reports
// get_report_data("01-01-2023",10).then(async res=>{
//   console.log(res)
//      const { data, error } = await supabase
//     .from('proceedings_couting_caisse')
//     .insert([
//       { caisse: 10, NetSold: res[0].TotalNet,user_name:"asma",B2000:0,B1000:0,B500:0,B200:0,C200:0,C100:0,C50:0,C20:0,C10:0,C5:0,state:"created" },
//     ])
//     if(error)console.log(error)
// })







// insert this report into proceding counting table

// admin validate this report

// user cant change

// admin send this report to coffre

// coffre admin validate

// report  insert into coffre in alimentation with that caisse


    // const { data, error } = await supabase
    // .from('proceedings_couting_caisse')
    // .insert([
    //   { caisse: 10, NetSold: 32314,user_name:"asma" },
    // ])
    // if(error)console.log(error)
}


export const activate_counting_caisse=async(proceding_id)=>{
  const { data, error } = await supabase
  .from('proceedings_couting_caisse')
  .update({ state: 'approved' })
  .eq('id', proceding_id)

}


export const deactivate_counting_caisse=async(proceding_id)=>{
  const { data, error } = await supabase
  .from('proceedings_couting_caisse')
  .update({ state: 'created' })
  .eq('id', proceding_id)

}


export const transfer_counting_caisse=async(proceding_id)=>{
  const { data, error } = await supabase
  .from('proceedings_couting_caisse')
  .update({ state: 'transferd' })
  .eq('id', proceding_id)

}

export const cancel_transfer_counting_caisse=async(proceding_id)=>{
  const { data, error } = await supabase
  .from('proceedings_couting_caisse')
  .update({ state: 'approved' })
  .eq('id', proceding_id)

}


export const validate_transfer=async(data)=>{
    // add this data to income coffre
console.log(data)


 
const { data:incomes_coffre, error } = await supabase
.from('incomes_coffre')
.insert([
  { sold: data.NetSold,note:data.note, type: 'alimentation_caisse', caisse: data?.caisse, B2000: Number(data.B2000), B1000: Number(data.B1000), B500: Number(data.B500), B200: Number(data.B200), C200: Number(data.C200), C100: Number(data.C100), C50: Number(data.C50), C20: Number(data.C20), C10: Number(data.C10), C5: Number(data.C5)},
       
])
.select()
if(incomes_coffre)console.log("data inserted")
if(error) console.log(error)

}

export const accept_transfer=async(data)=>{

  const { data:proceedings_couting_caisse, error } = await supabase
  .from('proceedings_couting_caisse')
  .update({ validate: true })
  .eq('id',data?.id )

}
