import React from 'react'
import { useState, useEffect } from 'react'

// Material Ui
import { Grid, Box, TextField, Chip, RadioGroup, FormLabel, FormControlLabel, Radio, OutlinedInput, InputAdornment, Typography } from '@mui/material'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { SimpleGrid } from '@chakra-ui/react'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { newTransfer } from "../../redux/slice"

//Supabase
import supabase from 'src/supabase.config';


//Libs
import currencyFormatter from "currency-formatter"
import CaisseComponent from './CaisseComponent';




const InfoForm = () => {
  const dispatch = useDispatch()
  const Transfer_Data = useSelector((state) => state.caisse.new_transfer)
  const [company_out, setCompany_out] = React.useState(0)
  const [company_in, setCompany_in] = React.useState(0)
  const [company_out_balance, setcompany_out_balance] = useState(0)
  const [company_in_balance, setcompany_in_balance] = useState(0)
  const [company_out_balance_old, setcompany_out_balance_old] = useState(0)
  const [company_in_balance_old, setcompany_in_balance_old] = useState(0)
  const [sold, setsold] = useState(0)
  const handlenewTransfer = async (e) => {
    if (e.target.id == 'sold') {
      setsold(e.target.value)
      dispatch(newTransfer({ key: 'sold', value: e.target.value }))
    }
    if (e.target.id == 'taxpayer') {
      dispatch(newTransfer({ key: 'taxpayer', value: e.target.value }))
    }
    if (e.target.name == 'caisse_out') {
      setCompany_out(e.target.value)
      let { data: caisse_out, error } = await supabase
        .from('caisse')
        .select('balance').eq('id', e.target.value)
      if (caisse_out) {
        setcompany_out_balance(caisse_out[0].balance - sold)
        setcompany_out_balance_old(caisse_out[0].balance)
        dispatch(newTransfer({ key: 'new_sold_out', value: caisse_out[0].balance }))

      }
      dispatch(newTransfer({ key: 'caisse_out', value: e.target.value }))
    }
    if (e.target.name == 'caisse_in') {
      setCompany_in(e.target.value)
      let { data: caisse_in, error } = await supabase
        .from('caisse')
        .select('balance').eq('id', e.target.value)
      if (caisse_in) {
        setcompany_in_balance(caisse_in[0].balance + Number(sold))
        setcompany_in_balance_old(caisse_in[0].balance)
        dispatch(newTransfer({ key: 'new_sold_in', value: caisse_in[0].balance }))
      }
      dispatch(newTransfer({ key: 'caisse_in', value: e.target.value }))
    }
    if (e.target.id == 'errand_period') {
      dispatch(newTransfer({ key: 'errand_period', value: e.target.value }))
    }
    if (e.target.name == 'errand_type') {
      dispatch(newTransfer({ key: 'errand_type', value: e.target.value }))
    }
    console.log(Transfer_Data)
  }



  const handleChange = (event) => {
    setCompany_out(event.target.value)
  };
  useEffect(() => {
    setcompany_out_balance(company_out_balance_old - sold)
    setcompany_in_balance(company_in_balance_old + Number(sold))
  }, [sold]);

  return (
    <Box sx={{ width: '100%', height: '300px', backgroundColor: "", mt: 2, mb: 5 }}>
      <SimpleGrid columns={2} spacing={10}>
        <FormControl  >
          <InputLabel htmlFor="outlined-adornment-amount"> المبلغ     المحول</InputLabel>
          <OutlinedInput
            sx={{ direction: 'rtl' }}
            id="sold"
            value={sold}

            onChange={(e) => handlenewTransfer(e)}
            endAdornment={<InputAdornment position="start"> دج</InputAdornment>}
            label="المبلغ"
          />
        </FormControl>
        <TextField size='small' id="taxpayer" label="  المكلف" variant="outlined" onChange={(e) => handlenewTransfer(e)} />
        <FormControl >
          <InputLabel id="caisse_out">من الصندوق</InputLabel>
          <Select
            labelId="caisse_out"
            name="caisse_out"

            value={company_out}
            label="caisse_out"
            onChange={(e) => { handlenewTransfer(e); console.log(e.target.name) }}
          >
            <MenuItem value={1}>IFRI</MenuItem>
            <MenuItem value={2}>MDD</MenuItem>
            <MenuItem value={3}>MMG</MenuItem>
          </Select>
        </FormControl>
        {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}




        <FormControl >


          <InputLabel id="demo-simple-select-label">الى الصندوق</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            name="caisse_in"
            value={company_in}
            label="Company in"

            onChange={(e) => { handlenewTransfer(e) }}
          >
            <MenuItem value={1}>IFRI</MenuItem>
            <MenuItem value={2}>MDD</MenuItem>
            <MenuItem value={3}>MMG</MenuItem>
          </Select>
        </FormControl>


        <TextField id="outlined-basic" label={"الرصيد الحالي" + company_out} variant="outlined" />
        <TextField id="outlined-basic" label={"الرصيد الحالي" + company_in} variant="outlined" />

        <TextField id="outlined-basic" label={"الرصيد الجديد" + company_out} variant="outlined" />
        <TextField id="outlined-basic" label={"الرصيد الجديد" + company_in} variant="outlined" />


        <TextField id="errand_period" size='small' label="   مدة الارجاع" variant="outlined" onChange={(e) => handlenewTransfer(e)} />
        <FormControl >
          <FormLabel id="demo-row-radio-buttons-group-label">ارجاع القرض</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="errand_type"
            size="small"
            onChange={(e) => handlenewTransfer(e)}
          >
            <FormControlLabel value="auto" control={<Radio />} label="تلقائي" />
            <FormControlLabel value="system" control={<Radio />} label="مستخدم" />

          </RadioGroup>
        </FormControl>

      </SimpleGrid>
    </Box>
  )
}

export default InfoForm







{/* <Box  sx={{ width: '100%', height: '300px' ,backgroundColor:"",mt:2,mb:5}}>
<Grid container spacing={1}>
<Grid item xs={6}>
<Typography  >
المبلغ المحول
</Typography>
</Grid>
<Grid item xs={6}>
<FormControl fullWidth >
<InputLabel htmlFor="outlined-adornment-amount"> المبلغ     المحول</InputLabel>
<OutlinedInput
sx={{direction:'rtl'}}
  id="sold"
  value={sold}
  size='small'
  onChange={(e) => handlenewTransfer(e)}
  endAdornment={<InputAdornment position="start"> دج</InputAdornment>}
  label="المبلغ"
/>
</FormControl>
</Grid>
<Grid item xs={6}>
<Typography  >
المكلف
</Typography>
</Grid>
<Grid item xs={6}>
<FormControl fullWidth >
<TextField size='small' id="taxpayer" label="  المكلف" variant="outlined" onChange={(e) => handlenewTransfer(e)} />

</FormControl>

</Grid>
<Grid item xs={6}>
<Typography  >
من الصندوق
</Typography>
</Grid>
<Grid item xs={6}>
<FormControl fullWidth>
   <InputLabel id="caisse_out">من الصندوق</InputLabel>
   <Select
     labelId="caisse_out"
   name="caisse_out"
   size='small'
     value={company_out}
     label="caisse_out"
     onChange={(e) => { handlenewTransfer(e); console.log(e.target.name) }}
   >
     <MenuItem value={1}>IFRI</MenuItem>
     <MenuItem value={2}>MDD</MenuItem>
     <MenuItem value={3}>MMG</MenuItem>
   </Select>
   </FormControl>
</Grid>
<Grid item xs={6}>
<Typography  >
الى الصندوق
</Typography>
</Grid>
<Grid item xs={6}>
<FormControl fullWidth>


<InputLabel id="demo-simple-select-label">الى الصندوق</InputLabel>
<Select
labelId="demo-simple-select-label"
name="caisse_in"
value={company_in}
label="Company in"
size='small'
onChange={(e) => { handlenewTransfer(e) }}
>
<MenuItem value={1}>IFRI</MenuItem>
<MenuItem value={2}>MDD</MenuItem>
<MenuItem value={3}>MMG</MenuItem>
</Select>
<br></br>

</FormControl>
</Grid>
<Grid item xs={6}>
<Typography  >
مدة الارجاع
</Typography>
</Grid>
<Grid item xs={6}>
<FormControl fullWidth>
<TextField id="errand_period" size='small' label="   مدة الارجاع" variant="outlined" onChange={(e) => handlenewTransfer(e)} />

</FormControl>


</Grid>

<Grid item xs={6}>
<FormControl>
<FormLabel id="demo-row-radio-buttons-group-label">ارجاع القرض</FormLabel>
<RadioGroup
row
aria-labelledby="demo-row-radio-buttons-group-label"
name="errand_type"
size="small"
onChange={(e)=>handlenewTransfer(e)}
>
<FormControlLabel value="auto" control={<Radio />} label="تلقائي" />
<FormControlLabel value="system" control={<Radio />} label="مستخدم" />

</RadioGroup>
</FormControl>
</Grid>
{/* <Grid item xs={6}>

  <Chip sx={{ mt: 1,width:'250px' }} label={"الرصيد القديم" +
    currencyFormatter.format(company_out_balance_old, {
      symbol: "دج",
      decimal: ",",
      thousand: ".",
      precision: 2,
      format: "%v %s", // %s is the symbol and %v is the value
    })} />


  <Chip sx={{ mt: 1,width:'250px' }} label={"الرصيد الجديد" +
    currencyFormatter.format(company_out_balance, {
      symbol: "دج",
      decimal: ",",
      thousand: ".",
      precision: 2,
      format: "%v %s", // %s is the symbol and %v is the value
    })} />

</Grid>

<Grid item xs={6}>

<Chip sx={{ mt: 1,width:'250px' }} label={"الرصيد القديم" +
 currencyFormatter.format(company_in_balance_old, {
   symbol: "دج",
   decimal: ",",
   thousand: ".",
   precision: 2,
   format: "%v %s", // %s is the symbol and %v is the value
 })} />


<Chip sx={{ mt: 1,width:'250px' }} label={"الرصيد الجديد" +
 currencyFormatter.format(company_in_balance_old, {
   symbol: "دج",
   decimal: ",",
   thousand: ".",
   precision: 2,
   format: "%v %s", // %s is the symbol and %v is the value
 })} />

</Grid> */}

// </Grid>

// </Box> */}