import supabase from "src/supabase.config";
import currencyFormatter  from "currency-formatter"



export const get_all_Transfers=async(startDate,endDate)=>{
    let { data: Ttransfer_coffre, error } = await supabase
    .from('Ttransfer_coffre')
    .select('*')
    .gte('Date', startDate)
    .lte('Date', endDate)
if (Ttransfer_coffre)
    return Ttransfer_coffre
}




export const format_sold = (sold) => {
    return currencyFormatter.format(sold, {
        symbol: "دج",
        decimal: ",",
        thousand: ".",
        precision: 2,
        format: "%v %s", // %s is the symbol and %v is the value
    })
}




export const Edit_income=async(income_id,B2000,B1000,B500,B200,C200,C100,C50,C20,C10,C5)=>{
    let PaperSold=B2000*2000+B1000*1000+B500*500+B200*200
    let CoinSold=C200*200+C100*100+C50*50+C20*20+C10*10+C5*5
    let Sold=B2000*2000+B1000*1000+B500*500+B200*200+C200*200+C100*100+C50*50+C20*20+C10*10+C5*5
  const { data:incomes_coffre, error } = await supabase
  .from('incomes_coffre')
  .update({ B2000:B2000,B1000:B1000,B500:B500,B200:B200,C200:C200,C100:C100,C50:C50,C20:C20,C10:C10,C5:C5,sold:Sold  })
  .eq('id', income_id)
  .select()
  if(incomes_coffre)return incomes_coffre
  if(error)console.log(error)
  
  }



  export const Edit_spend=async(spend_id,B2000,B1000,B500,B200,C200,C100,C50,C20,C10,C5)=>{
    let PaperSold=B2000*2000+B1000*1000+B500*500+B200*200
    let CoinSold=C200*200+C100*100+C50*50+C20*20+C10*10+C5*5
    let Sold=B2000*2000+B1000*1000+B500*500+B200*200+C200*200+C100*100+C50*50+C20*20+C10*10+C5*5
  const { data:spends_coffre, error } = await supabase
  .from('spends_coffre')
  .update({ B2000:B2000,B1000:B1000,B500:B500,B200:B200,C200:C200,C100:C100,C50:C50,C20:C20,C10:C10,C5:C5,sold:Sold  })
  .eq('id', spend_id)
  .select()
  if(spends_coffre)return spends_coffre
  if(error)console.log(error)
  
  }


  export const Edit_transfer=async(transfer_id,B2000,B1000,B500,B200,C200,C100,C50,C20,C10,C5)=>{
    let PaperSold=B2000*2000+B1000*1000+B500*500+B200*200
    let CoinSold=C200*200+C100*100+C50*50+C20*20+C10*10+C5*5
    let Sold=B2000*2000+B1000*1000+B500*500+B200*200+C200*200+C100*100+C50*50+C20*20+C10*10+C5*5
  const { data:Ttransfer_coffre, error } = await supabase
  .from('Ttransfer_coffre')
  .update({ B2000:B2000,B1000:B1000,B500:B500,B200:B200,C200:C200,C100:C100,C50:C50,C20:C20,C10:C10,C5:C5,sold:Sold  })
  .eq('id', transfer_id)
  .select()
  if(Ttransfer_coffre)return Ttransfer_coffre
  if(error)console.log(error)
  
  }