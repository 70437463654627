import { eq } from "lodash"
import supabase from "src/supabase.config"



export const Authentication=async(Name,password)=>{
    console.log("what the fuck")
    console.log(Name)
    console.log(password)
   
    let { data: users, error } = await supabase
    .from('users')
    .select('*')
    .eq("Name",Name)
    .eq("Password",password)
   if(users)console.log(users)
    if(users.length>0){
        console.log(users)
        return users
    }
  
}

