import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// component
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------



// Child Components
import Showmodal from "./showModal"
import Deletemodal from "./deleteModal"
import Printmodal from "./printModal"
import Editmodal from "./editModal"
// Action of Item of this ID
export default function MoreMenu({ data }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >

        <Editmodal data={data} />
        <Deletemodal data={data} />

        <Showmodal data={data} />
        <Printmodal data={data} />

      </Menu>
    </>
  );
}
