import * as React from 'react';
import {useState} from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { SimpleGrid } from '@chakra-ui/react'
export default function DetailsForm2({data}) {
    const [value, setValue] = React.useState('1');

   

    const [B2000, setB2000] = useState(data?.B2000)
    const [B1000, setB1000] = useState(data?.B1000)
    const [B500, setB500] = useState(data?.B500)
    const [B200, setB200] = useState(data?.B200)


    const [C200, setC200] = useState(data?.C200)
    const [C100, setC100] = useState(data?.C100)
    const [C50, setC50] = useState(data?.C50)
    const [C20, setC20] = useState(data?.C20)
    const [C10, setC10] = useState(data?.C10)
    const [C5, setC5] = useState(data?.C5)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box sx={{ width: '100%', typography: 'body1', direction: "rtl" }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="ورق " value="1" />
                        <Tab label="معدن " value="2" />

                    </TabList>
                </Box>
                <TabPanel value="1">
                    <SimpleGrid columns={2} spacing={10}>


                        <FormControl sx={{ mb:5, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>ورق</InputLabel>
                            <OutlinedInput
                            value={B2000}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "2000 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>
                        <FormControl sx={{ mb:5, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>ورق</InputLabel>
                            <OutlinedInput
                              value={B1000}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "1000 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>
                        <FormControl sx={{ mb:5, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>ورق</InputLabel>
                            <OutlinedInput
                              value={B500}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "500 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb:5, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>ورق</InputLabel>
                            <OutlinedInput
                              value={B200}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start" >


                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "200 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>
                    </SimpleGrid>
                </TabPanel>
                <TabPanel value="2">
                    <SimpleGrid columns={3} spacing={2}>


                        <FormControl sx={{ mb:5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                              value={C200}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "200 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb:5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                              value={C100}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "100 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb:5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                              value={C50}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "50 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb:5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                              value={C20}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "20 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb:5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                              value={C10}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "10 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ mb:5, width: '18ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                              value={C5}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "5 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                    </SimpleGrid>

                </TabPanel>

            </TabContext>
        </Box>
    );
}
