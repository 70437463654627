import * as React from 'react';
import Box from '@mui/material/Box';
 import {useState} from "react"
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField, Button } from "@mui/material";
import { SimpleGrid } from '@chakra-ui/react';
import { Add } from '@mui/icons-material';
import supabase from 'src/supabase.config';

const initialFormData = {
  Code: "",
  label: "",
  Tel: "",
  per_contact: "",
  adress: "",
  rg_commerce: "",
  NIF: "",
  NIS: "",
  N_Article: "",
};
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height:"auto",
bgcolor:'white',
borderRadius:"4px",

  
  boxShadow: 24,
  p: 4,
};

export default function ForniseurModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {setOpen(true);setFormData(initialFormData)}
  const handleClose = () => setOpen(false);
  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    console.log(formData);
    // handle form submission logic here wich send it to supabase
    const { data, error } = await supabase.from('Forniseur').insert(formData);

    if (error) {
      console.error(error);
    } else {
      console.log(data);
      handleClose()
    }
  };
  return (
    <div >
      <Button variant="contained" onClick={handleOpen}>
      <Add/>   اضافة مورد
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
        <Box dir="rtl" sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          اضافة مورد
          </Typography>
          <form onSubmit={handleSubmit}>
          <SimpleGrid columns={2} spacing={10}>
 

      <TextField
        label="Code"
        name="Code"
        value={formData.Code}
        onChange={handleInputChange}
        margin="normal"
        variant="outlined"
      />
       <TextField
        label="nom  et prenom"
        name="label"
        value={formData.label}
        onChange={handleInputChange}
        margin="normal"
        variant="outlined"
      />
       <TextField
        label="Telephone"
        name="Tel"
        value={formData.Tel}
        onChange={handleInputChange}
        margin="normal"
        variant="outlined"
      />
        
  
       <TextField
        label="Perssone de contact"
        name="per_contact"
        value={formData.per_contact}
        onChange={handleInputChange}
        margin="normal"
        variant="outlined"
      />
       <TextField
        label="Adress"
        name="adress"
        value={formData.adress}
        onChange={handleInputChange}
        margin="normal"
        variant="outlined"
      />
       <TextField
        label="Register commerce"
        name="rg_commerce"
        value={formData.rg_commerce}
        onChange={handleInputChange}
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="NIF"
        name="NIF"
        value={formData.NIF}
        onChange={handleInputChange}
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="NIS"
        name="NIS"
        value={formData.NIS}
        onChange={handleInputChange}
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="Numero Article"
        name="N_Article"
        value={formData.N_Article}
        onChange={handleInputChange}
        margin="normal"
        variant="outlined"
      />
      <Box>

      </Box>
      <Button  size='small' type="submit" variant="contained" color="primary">
        حفظ
      </Button>
      <Button  size='small' type="submit" variant="contained" color="error">
        الغاء
      </Button>
      </SimpleGrid>
    </form>
        </Box>
      </Modal>
    </div>
  );
}
